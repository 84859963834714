import UseEliteVSAddPoints from "../Library/useEliteVSAddPoints";
import SearchInput from '../../../GlobalComponents/SearchInput';
import { withApollo } from 'react-apollo';
import NoItems from "../../../GlobalComponents/NoItems";
import close from '../../../Public/Images/remove.svg';
import Error from "../../../GlobalComponents/Error";

const EliteVSAddPoints = (props) => {

    const {
        formData,
        years,
        error,
        searchedVsData,
        selectedCustomers,
        FormHandle,
        GetVsList,
        AddVs,
        RemoveSelectedVs,
        AddPoints
    } = UseEliteVSAddPoints(props.client); 
        
    return(
        <div className="row" >
            <div className="col-sm one">
                <div className="card main">
                    
                    <div className="card-header d-flex align-items-center">
                        Hromadné přidání bodů
                        <button className="btn btn-primary btn-thiner ml-auto" onClick={() => AddPoints()}>Přidat</button>
                    </div>
                    <div className="card-body">

                        {error ?
                            <Error text={error} />
                        :
                            <>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <label className="input-label">Popis (za co se body přidávají)</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="info" value={formData.info} onChange={(e) => FormHandle(e)} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-2">
                                        <label className="input-label">Počet bodů </label>
                                        <div className="form-group">
                                            <input className="form-control" type="number" name="points" value={formData.points} onChange={(e) => FormHandle(e)} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-2">
                                        <label className="input-label">Pro období </label>
                                        <div className="form-group">
                                            <select value={formData.month} className="form-control" name = "month" onChange={(e) => FormHandle(e)}>
                                                <option value="1">leden</option>
                                                <option value="2">únor</option>
                                                <option value="3">březen</option>
                                                <option value="4">duben</option>
                                                <option value="5">květen</option>
                                                <option value="6">červen</option>
                                                <option value="7">červenec</option>
                                                <option value="8">srpen</option>
                                                <option value="9">září</option>
                                                <option value="10">říjen</option>
                                                <option value="11">listopad</option>
                                                <option value="12">prosinec</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-2">
                                        <label className="input-label">&nbsp;</label>
                                        <div className="form-group">
                                            <select value={formData.year} name="year" className="form-control mr-1" onChange={(e) => FormHandle(e)}> 
                                                {years.map((item,index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))} 
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="input-label">Komu se mají body přidat ?</label>
                                        <div className="form-group">
                                            <SearchInput value={searchedVsData.searchText} className="form-control" placeholder="Vyhledat Vládce síly" onChange={(text) => GetVsList(text)} />
                                        </div>
                                    </div>
                                </div>

                                {searchedVsData.list && searchedVsData.list.length > 0 ?
                                    <div className="data-list">
                                                                
                                        {searchedVsData.list.map((item) => (
                                            <div key={item.customerID} className="data-list-item-content">
                                                <div className="static">{item.name}</div>
                                                <div className="static">{item.surname}</div>
                                                <div className="static">{item.astralName}</div>
                                                <div className="">{item.email}</div>
                                                
                                                <div className="text-right static small">
                                                    <button className="btn btn-primary" onClick={() => AddVs(item)}>Přidat</button>
                                                </div>
                                            </div>
                                        ))}
                                        
                                    </div>
                                :null}

                                <div>
                                    <div className="data-list">
                                                                
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                
                                                <div className="static">Jméno</div>
                                                <div className="static">Příjmení</div>
                                                <div className="static">Char. jméno</div>
                                                <div className="">Email</div>
                                                
                                                <div className="text-right static small">Možnosti</div>
                                            </div>
                                        </div>

                                        {selectedCustomers && selectedCustomers.length > 0 ?
                                            
                                            (selectedCustomers.map((item) => (
                                                <div key={item.customerID} className="data-list-item-content">
                                                    <div className="static">{item.name}</div>
                                                    <div className="static">{item.surname}</div>
                                                    <div className="static">{item.astralName}</div>
                                                    <div className="">{item.email}</div>
                                                    
                                                    <div className="text-right static small">
                                                        <img onClick={() => RemoveSelectedVs(item.customerID)} src={close} className="remove-icon" />
                                                    </div>
                                                </div>
                                            )))
                                            
                                        :
                                            <NoItems text="Zatím nebyl přidán žádný VS, kterému by se měly přidat body." />
                                        }
                                        
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withApollo(EliteVSAddPoints);