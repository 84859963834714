import { useState,useMemo } from "react";
import moment from 'moment';
import { GET_CUSTOMERS } from "../../Customer/Queries/customer";
import Notification from "../../../Library/notification";
import { ADD_TO_MULTIPLE_CUSTOMER_EVS_POINTS } from "../Queries/eliteVS";
import { useMutation } from "react-apollo";
import Helper from '../../../Library/helper';

const INIT_FORM_DATA = {
    points:0,
    month: moment().format("M"),
    year: moment().format("YYYY"),
    info:"",
    selectedCustomers:[]
} 

const UseEliteVSAddPoints = (client) => {

    const years = useMemo(() => {
        
        let years = [];
        let yearToday = moment().format("YYYY");
        for(let i = 2021; i <= parseInt(yearToday)+1; i++){
            years.push(i);
        }
        return years;

    },[]);

    const [error, SetError] = useState("");
    const [selectedCustomers, SetSelectedCustomers] = useState([]);
    const [formData, SetFormData] = useState(INIT_FORM_DATA);
    const [searchedVsData, SetSearchedVsData] = useState({searchText:"",list:[]});

    const [AddToMultipleCustomerEVSPoints] = useMutation(ADD_TO_MULTIPLE_CUSTOMER_EVS_POINTS,{
        onError:(err) => {

            const helper = new Helper();
            SetError(helper.getApolloErrorText(err));
        }, 
        onCompleted:() => {
            const notify = new Notification();
            notify.setNotification(null,"A už jim to letí, ti se pomějou 🥰",true,false,client);
        }
    });

    const GetVsList = async (text) => {

        if(text && text != "")
        {
            try
            {
                var allVS = await client.query({
                    query: GET_CUSTOMERS,
                    fetchPolicy: 'network-only',
                    variables:{
                        text:text,
                        offset:0,
                        limit:50
                    }
                });

                SetSearchedVsData({...searchedVsData,
                    searchText:text,
                    list:allVS.data.allCustomers
                });
            }
            catch(err)
            {
                const helper = new Helper();
                SetError(helper.getApolloErrorText(err));
            }
        }
        else
        {
            SetSearchedVsData({...searchedVsData,
                searchText:"",
                list:[]
            });
        }
    }

    const AddPoints = () => {

        if(formData.points && formData.points != 0)
        {
            if(formData.info && formData.info != "")
            {
                if(formData.selectedCustomers && formData.selectedCustomers.length > 0)
                {
                    AddToMultipleCustomerEVSPoints({
                        variables:{
                            points:             parseInt(formData.points),
                            month:              parseInt(formData.month),
                            year:               parseInt(formData.year),
                            info:               formData.info,
                            selectedCustomers:  formData.selectedCustomers
                        }
                    })
                }
                else
                {
                    const notify = new Notification();
                    notify.setNotification(null,"Bez přidaných Vládců zůstanou body pouze v tvé mysli :)",false,true,client);
                }
            }
            else
            {
                const notify = new Notification();
                notify.setNotification(null,"Dej jim vědět za co body dostali, ať to není podezřelé :)",false,true,client);
            }
        }
        else
        {
            const notify = new Notification();
            notify.setNotification(null,"Chudáci Vládci, aspoň jeden bod jim dej :)",false,true,client);
        }
    }

    const AddVs = (item) => {

        var check = false;
        for(let val of formData.selectedCustomers)
        {
            if(val == item.customerID)
                check = true;

        }

        if(!check)
        {
            var arr = [...formData.selectedCustomers];
            var selCusts = [...selectedCustomers];

            arr.push(item.customerID);
            selCusts.push(item);

            SetFormData({...formData,selectedCustomers:arr});
            SetSelectedCustomers(selCusts);
        }    
        else
        {
            const notify = new Notification();
            notify.setNotification(null,"Ty jo, 2x stejného VS tady nedáš a můžeš se snažit jak chceš :)",false,true,client);
        }

        SetSearchedVsData({...searchedVsData,
            searchText:"",
            list:[]
        });
    }

    const RemoveSelectedVs = (customerID) => {

        var arr = [...formData.selectedCustomers];
        var selCusts = [...selectedCustomers];

        for(let i in selCusts)
        {
            if(selCusts[i].customerID == customerID)
                selCusts.splice(i,1);
        }

        for(let i in arr)
        {
            if(arr[i] == customerID)
                arr.splice(i,1);
        }

        SetSelectedCustomers(selCusts);
        SetFormData({...formData,selectedCustomers:arr});

    }

    const FormHandle = (e) => {

        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        SetFormData({...formData,[n]: v});
    }

    return {
        formData,
        years,
        searchedVsData,
        error,
        selectedCustomers,
        FormHandle,
        GetVsList,
        AddVs,
        RemoveSelectedVs,
        AddPoints
    }
}

export default UseEliteVSAddPoints;