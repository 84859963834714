import { useMutation } from "react-apollo";
import { ADD_EDIT_NOTIFICATION } from "../../Queries/notification";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import Notification from "../../../../Library/notification";
import Helper from "../../../../Library/helper";
import { GET_CUSTOMERS } from "../../../Customer/Queries/customer";

const UseAddEditNotification = (client,selectedNotificationID,lang,OnCompleted) => {

    const notifi = new Notification();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        selectedLang:lang,
        sendEmailAboutReadToGuideID:0,
        isPopUp:1,
        sendTo:1,
        searchText:"",
        selectedCustomers:[],
        allCustomers:[],
        langs:[]
    });

    const [AddEditNotifi,{error,loading}] = useMutation(ADD_EDIT_NOTIFICATION,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditNotification);

            const notifyText = (selectedNotificationID != 0 ? "Úspěšně upraveno" : "Odesláno");
            notifi.setNotification(null,notifyText,true,false,client);

        },
        onError:(err) => {

            const helper = new Helper();
            notifi.setNotification(null,helper.getApolloErrorText(err),true,false,client);
        }
    });

    const AddEditNotification = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.text === "" && (val.lang == "cz" || val.lang == "sk"))
                check = false;

            if(val.title === "" && (val.lang == "cz" || val.lang == "sk"))
                check = false;
        }

        if(check)
        {
            if(formData.sendTo == 1 || formData.sendTo == 2 && formData.selectedCustomers.length > 0)
            {
                let langs = [];
                for(const lang of formData.langs)
                {
                    langs.push({
                        title: lang.title,
                        text:  lang.text,               
                        lang:  lang.lang
                    });
                }

                var selectedCust = [];
                if(formData.selectedCustomers)
                {
                    for(let val of formData.selectedCustomers)
                    {
                        selectedCust.push(val.customerID);
                    }
                }

                AddEditNotifi({
                    variables:{
                        notificationID:                 selectedNotificationID,
                        sendEmailAboutReadToGuideID:    formData.sendEmailAboutReadToGuideID,
                        isPopUp:                        formData.isPopUp,
                        selectedCustomers:              selectedCust,
                        langs:                          langs
                    }
                })
            }
            else
                notifi.setNotification(null,"Bez vybraných lidí to nepůjde.",false,true,client);
            
        }
        else
            notifi.setNotification(null,"Nevyplnili jste název nebo text. Zkontroluj i jazykové mutace.",false,true,client); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const SetCustomer = (customer) => {

        var check = false;
        for(let val of formData.selectedCustomers)
        {
            if(val.customerID == customer.customerID)
                check = true;
        }

        if(!check)
        {
            var arr = [...formData.selectedCustomers];
            arr.push(customer);

            SetFormData("selectedCustomers",arr);
            GetCustomers("");
        }
        else
            notifi.setNotification(null,"Tento člověk už tam je :)",false,true,client);
    }

    const GetCustomers = async(text) => {

        if(text != "")
        {
            var data = await client.query({
                query:GET_CUSTOMERS,
                variables:{
                    offset:0, 
                    limit:100000,
                    text:text
                },
                fetchPolicy: 'network-only'
            });

            if(data)
            {
                SetFormData("allCustomers",data.data.allCustomers);
                SetFormData("searchText",text);
            }
        }
        else
        {
            SetFormData("allCustomers",[]);
            SetFormData("searchText","");
        }
    }

    const RemoveCustomer = (customerID) => {

        var arr = [...formData.selectedCustomers];
        for(let i in arr)
        {
            if(arr[i].customerID == customerID)
                arr.splice(i,1);
        }

        SetFormData("selectedCustomers",arr);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditNotification,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        SetCustomer,
        GetCustomers,
        RemoveCustomer
    }

}

export default UseAddEditNotification;