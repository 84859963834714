const NextListItems = ({buttonText,offset,fetchMore,listObject}) => {

    return(
        <div className="text-center p-5">
            <button className="btn btn-primary" onClick={() => fetchMore({
                variables: {
                    offset: offset
                },
                updateQuery: (prev, { fetchMoreResult }) => {

                    var obj = {};
                    obj[listObject] = [...prev[listObject], ...fetchMoreResult[listObject]];

                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, obj);
                }
            })}> {buttonText} </button>
        </div> 
    )

}

export default NextListItems;