import React,{useState} from 'react';
import {withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMER_DAILY_DATA} from '../Queries/customer';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import moment from 'moment';
import Helper from '../../../Library/helper';

const AllCustomerDailyPosts = (props) => {

    const [messageType, setMessageType] = useState({
        type: 1,
        name: "Příspěvky",
        showMobileTabs:false,
        updateReadedPosts: false
    });

    var offset = 0;
    var limit = 20;
    var variables = {
        customerID: (props.customerID ? props.customerID : 0),
        offset,
        limit,
        messageType:messageType.type
    }

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_CUSTOMER_DAILY_DATA,{
        fetchPolicy: 'network-only',
        variables
    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  

    }

    return(
        <div>

            {err ?
                <Error text = {err} />
            :
                <div className="daily-posts">
                    <div className={"card form-group" + (props.myMotivation != "" ? "" : (props.isVSList ? " no-margin" : " small-margin"))}>

                        {props.myMotivation != "" ?
                            <div className="card-body">

                                <div className="alert alert-info no-margin"><strong>Motivace:</strong> {props.myMotivation}</div>
                                
                            </div>
                        :null}

                    </div>

                    <div className="position-relative">

                        <ul className={"nav nav-tabs " + (messageType.showMobileTabs ? "open" : "")}>

                           
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:1,name:"Příspěvky"})}} className={"nav-link" + (messageType.type == 1 ?  " active" : "")}>
                                    Příspěvky
                                </a>
                            </li>

                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:22,name:"Můj postup"})}} className={"nav-link" + (messageType.type == 22 ?  " active" : "")}>
                                    Můj postup
                                </a>
                            </li>

                            
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:2,name:"Sny"})}} className={"nav-link" + (messageType.type == 2 ?  " active" : "")}>
                                    Sny
                                </a>
                            </li>
                            
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:3,name:"Synchronicity"})}} className={"nav-link" + (messageType.type == 3 ?  " active" : "")}>
                                    Synchronicity
                                </a>
                            </li>
                           
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:4,name:"Nehmotné zážitky"})}} className={"nav-link" + (messageType.type == 4 ?  " active" : "")}>
                                    Nehmotné zážitky
                                </a>
                            </li>
                            
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:5,name:"Nechtěné vlastnosti"})}} className={"nav-link" + (messageType.type == 5 ?  " active" : "")}>
                                    Nechtěné vlastnosti
                                </a>
                            </li>
                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:6,name:"Chtěné vlastnosti"})}} className={"nav-link" + (messageType.type == 6 ?  " active" : "")}>
                                    Chtěné vlastnosti
                                </a>
                            </li>
                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:7,name:"Charizm. podstata"})}} className={"nav-link" + (messageType.type == 7 ?  " active" : "")}>
                                    Charizm. podstata
                                </a>
                            </li>
                        
                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:8,name:"30 radostí"})}} className={"nav-link" + (messageType.type == 8 ?  " active" : "")}>
                                    30 radostí
                                </a>
                            </li>

                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:19,name:"Transformace"})}} className={"nav-link" + (messageType.type == 19 ?  " active" : "")}>
                                    Transformace
                                </a>
                            </li>
                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:9,name:"Životní hodnoty"})}} className={"nav-link" + (messageType.type == 9 ?  " active" : "")}>
                                    Životní hodnoty
                                </a>
                            </li>
                        
                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:10,name:"Smysl života"})}} className={"nav-link" + (messageType.type == 10 ?  " active" : "")}>
                                    Smysl života
                                </a>
                            </li>

                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:11,name:"108 přání"})}} className={"nav-link" + (messageType.type == 11 ?  " active" : "")}>
                                    108 přání
                                </a>
                            </li>

                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:12,name:"8+1 sny"})}} className={"nav-link" + (messageType.type == 12 ? " active" : "")}>
                                    8+1 sny
                                </a>
                            </li>

                        
                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:13,name:"Herec"})}} className={"nav-link" + (messageType.type == 13 ? " active" : "")}>
                                    Herec
                                </a>
                            </li>

                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:21,name:"Minulé životy"})}} className={"nav-link" + (messageType.type == 21 ?  " active" : "")}>
                                    Minulé životy
                                </a>
                            </li>

                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:20,name:"Mé poznámky"})}} className={"nav-link" + (messageType.type == 20 ?  " active" : "")}>
                                    Mé poznámky
                                </a>
                            </li>

                            <li className="nav-item mb-3">
                                <a href="#" onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:23,name:"Průvodce"})}} className={"nav-link" + (messageType.type == 23 ?  " active" : "")}>
                                    Průvodce
                                </a>
                            </li>

                        </ul>

                    </div>

                    {loading ?
                        <Loading />
                    :
                        <>
                        
                            {data.allCustomerDailyPosts && data.allCustomerDailyPosts.length > 0 ?

                                <>
                                
                                    {data.allCustomerDailyPosts.map((item,index) => (

                                        <div key={index} className={"card form-group post " + ((data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5) && item.readByGuide == 0 && item.customerID != data.customerContext.customerID  ? "new" : "")}>

                                            <div className="card-body">

                                                <div className="header">
                                                    <div> {moment(item.dateAdd).format("DD.MM.YYYY HH:mm:ss")} <span className="from">{(item.guideID != 0 ? "OD: " + (item.guide.astralName ? item.guide.astralName : item.guide.name + " " + item.guide.surname) : "")}</span></div>
                                                </div>

                                                <div className = "content tiny-content" dangerouslySetInnerHTML={{__html:item.text}}></div>
                                                
                                            </div>

                                        </div>

                                    ))}

                                    {data.allCustomerDailyPostsCount > data.allCustomerDailyPosts.length ?

                                        <p className="text-center mt-5">
                                            <button 
                                                onClick={async  () => await fetchMore({
                                                    variables: {
                                                        customerID: (props.customerID ? props.customerID : 0),
                                                        offset:data.allCustomerDailyPosts.length,
                                                        limit
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                    
                                                    if (!fetchMoreResult) return prev.allCustomerDailyPosts;
                                                    return Object.assign({}, prev, {
                                                        allCustomerDailyPosts: [...prev.allCustomerDailyPosts, ...fetchMoreResult.allCustomerDailyPosts]
                                                    });
                                                    }
                                                })} 
                                                className="btn btn-primary">
                                                Načíst více
                                            </button>
                                        </p>
                                    
                                    :
                                    null}
                                
                                </>

                            :

                                <div className="card">

                                    <div className="card-body">

                                        <div className="alert alert-warning text-center no-margin">Zatím zde nejsou žádné zprávy</div>
                                        
                                    </div>

                                </div>

                            }
                        </>
                    } 
                </div>
            }

        </div>
    )

}

export default withApollo(AllCustomerDailyPosts);