/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

 import {GET_SETTINGS} from '../Queries/settings';
 
 class Settings {
 
     constructor(parent){
         this.parent = parent;
     }
      
     /**
      * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
      */
 
     async getData(){
         
         var data = await this.parent.props.client.query({ 
             query: GET_SETTINGS,
             errorPolicy:"all", 
             fetchPolicy: 'network-only',
             variables:{
                 lang:""
             }
         });
         
         
         if(data.errors){
             this.parent.setState({apolloError:data.errors[0].message});
         }
         //kdyz upravujeme zaznam
         console.log(data);
         if(data.data){                  
             if(data.data.settings){  
    
                 this.parent.setState({
                     apolloError:"",
                     formData:{...this.parent.state.formData,
                        openAula:data.data.settings.openAula,
                        closePortal:data.data.settings.closePortal
                    }
                 });
                 
             }else{
                 this.parent.setState({ apolloError:""});
             }
         }
 
     }

     saveRoomsSettings(saveRS){

        saveRS({
            variables:{
                settings:this.parent.state.formData
            }
        })

     }
 
     completeLangsArray = (langsFromDb,allActiveLanguages) => {
        
         let langs = langsFromDb;
         for(const l of allActiveLanguages){
             let langSet = false;
             for(const lang of langs){
                 if(l.suffix == lang.lang){
                     langSet = true;
                     break;
                 }
             }
 
             if(!langSet){
                 langs.push({
                     text:"",  
                     text2:"",                    
                     buttonText:"",
                     videoUrl:"",
                     videoDesc:"",
                     lang:l.suffix
                 });
             }
         }
         return langs;
     }
 
      
     /**
      * funkce ukládá data, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formHandle(e){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
               
     }
     /**
      * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formLangHandle(e,index){
         
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         let langs       = this.parent.state.formData.steps[index].langs;
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[n] = v;
             }
         }

         var arr = [...this.parent.state.formData.steps];
         arr[index] = {...arr[index],langs:langs}
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,steps: arr}});
          
     }
 
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
      * @param {String} data data tinyMCE editoru
      * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
      */
     
     formLangHandleEditor(content,paramName,index){
          
         let langs       = this.parent.state.formData.steps[index].langs;
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[paramName] = content;
             }
         }

         var arr = [...this.parent.state.formData.steps];
         arr[index] = {...arr[index],langs:langs}
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,steps: arr}});
     }
 
     /**
      * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
      * @param {String} paramName - název proměnné v objektu lang
      * 
      * @returns property value
      */
     getLangValue = (paramName,index) => {
         for(const lang of this.parent.state.formData.steps[index].langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 if(lang[paramName] === null){
                     return "";
                 }else{
                     return lang[paramName];
                 }
             }
         }
         return "";
     }
 
 
     
 }
 
 export default Settings;