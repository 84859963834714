/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
 import gql from 'graphql-tag';

 /**
 *  Data pro jednu kategorii
 */

export const GET_SETTINGS = gql`
    query GetRoomsSettings($lang:String){
        settings(lang:$lang){
            openAula 
            closePortal
        }
    }
`;

export const SAVE_SETTINGS = gql`
    mutation GetPortalSettings($settings:SettingsInput){
        savePortalSettings(settings:$settings)
    }
`;