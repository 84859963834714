import Helper from "../../../../Library/helper";
import { GET_NOTIFICATIONS } from "../../Queries/notification";
import { useQuery } from "react-apollo";
import { useState } from "react";

const UseGetAllNotifications = (variables) => {

    const helper = new Helper();

    const [selectedNotificationIDs,SetSelectedLinkIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_NOTIFICATIONS,{
        variables,
        fetchPolicy:"network-only"
    })

    const SelectNotification = (e,notificationID) => {

        var checked = e.target.checked;
        var arr = [...selectedNotificationIDs];

        if(!checked){
            for(let i in selectedNotificationIDs){
                if(selectedNotificationIDs[i] == notificationID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [notificationID,...arr];
        }

        SetSelectedLinkIDs(arr);

    }

    var err = "";
    if(error)
        err = helper.getApolloErrorText(error);

    return{
        error: err,
        loading,
        data: data ? data.AllNotifications : [],
        selectedNotificationIDs,
        SelectNotification
    }
}

export default UseGetAllNotifications;