import { useState } from "react";
import { DELETE_NOTIFICATIONS } from "../../Queries/notification";
import { useMutation } from "react-apollo";
import Notification from "../../../../Library/notification";
import Helper from "../../../../Library/helper";

const UseDeleteNotifications = (client,selectedItems,OnCompleted) => {

    const notifi = new Notification();
    const helper = new Helper();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelNotifications] = useMutation(DELETE_NOTIFICATIONS,{
        onCompleted:(d) => {

            notifi.setNotification(null,"A jsou pryč :)",true,false,client);
            if(OnCompleted)
                OnCompleted(d.DeleteNotifications);
        },
        onError:(err) => {          
            notifi.setNotification(null,helper.getApolloErrorText(err),false,true,client);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            notifi.setNotification(null,"Nevybrali jste notifikace ke smazání.",false,true,client);
        }
    }

    const DeleteNotifications = (action) => {

        if(action)
        {              
            DelNotifications({
                variables:{
                    notificationIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteNotifications
    }
}

export default UseDeleteNotifications;