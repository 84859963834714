import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class ButtonElement extends Component{

    constructor(props){
        super(props);
    }

    onPaste(e,type){
        e.preventDefault(); 
        const text = e.clipboardData.getData('text/plain'); 
        if(text != "")this.props.cm.setElementData(type,this.props.lastIndexes,"button",text,null,true);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("button",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Zarovnání:</label>
                                                    </div>
                                                    <select value={element.buttonAlign} className="form-control" name="buttonAlign" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                        <option value="start"> vlevo </option>
                                                        <option value="center"> centrovaně </option>
                                                        <option value="end"> vpravo </option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Odkaz:</label>
                                                    </div>
                                                    <input type="text" value={element.url} className="form-control" name="url" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}  />
                                                </div>
                                            </div>

                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Barva:</label>
                                                    </div>
                                                    <select value={element.color} className="form-control" name="color" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                        <option value=""> základní </option>
                                                        <option value="btn-green"> zelená </option>
                                                        <option value="btn-white"> bílá </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Velikost:</label>
                                                    </div>
                                                    <select value={element.size} className="form-control" name="size" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                        <option value=""> malá </option>
                                                        <option value="bigger"> střední </option>
                                                        <option value="biggest"> velká </option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("button",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit tlačítko" title="Odstranit tlačítko" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"button",lastIndexes)}}>
                    <div className={"d-flex justify-content-" + element.buttonAlign}>
                        
                        <div>
                            {element.url && element.url != "" ?
                                <ContentEditable onClick={(e) => e.preventDefault()} className={"btn btn-primary " + element.color + " " + element.size + " " + element.className} href={element.url} tagName={"a"} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"button",null,null,true)}} />
                            :
                                <button className={"btn btn-primary " + element.color + " " + element.size + " " + element.className}><ContentEditable onClick={(e) => e.preventDefault()} tagName={"span"} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"button",null,null,true)}} /></button>
                            }
                        </div>
                                                
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default ButtonElement;