import React,{ Component } from 'react';
import AllMaterials from './AllMaterials.js';
import AddEditMaterial from './AddEditMaterial';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showAddMaterial : false,
}

class Material extends Component{

    static contextType = AuthContext;

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal = this.openCloseModal.bind(this);

       this.authLib = new Auth();

    }

    openCloseModal(type){
        this.setState({
            showAddMaterial:type,
        })
    }

    render(){

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showMaterials)
            window.location.href="/";

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam materiálů
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                            </div>
                            <div className="card-body">
                                <AllMaterials />
                            </div>
                        </div>
                    </div>
                </div> 	
                {this.state.showAddMaterial &&
                    <AddEditMaterial openCloseModal={this.openCloseModal} />
                }		
            </div>
        );

    }

}

export default Material;