import { GET_NOTIFICATIONS } from "../../Queries/notification";

export const UpdateAfterAdd = (client,newData,variables) => {

    const { AllNotifications } = client.readQuery({ query: GET_NOTIFICATIONS ,variables});
    var arr = [newData,...AllNotifications];

    client.writeQuery({ 
        query:GET_NOTIFICATIONS,
        variables:variables,
        data:{
            AllNotifications: arr
        } 
    });

}

export const UpdateAfterDelete = (client,notificationIDs,variables) => {

    var resp = notificationIDs.split(",");

    const { AllNotifications } = client.readQuery({ query: GET_NOTIFICATIONS ,variables});
    var arr = [...AllNotifications];

    resp.forEach((it,ind) => {
        arr.forEach((item,index) => {
            if(item.notificationID == it){
                arr = [...arr.slice(0,index),...arr.slice(index + 1)]
            }
        });
    }) 

    client.writeQuery({ 
        query:GET_NOTIFICATIONS,
        variables,
        data:{
            AllNotifications: [...arr]
        } 
    });

}