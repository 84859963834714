import Error from "../../../GlobalComponents/Error";
import Loading from "../../../GlobalComponents/Loading";
import NoItems from "../../../GlobalComponents/NoItems";
import UseGetAllNotifications from "../Library/Notification/UseGetAllNotifications";
import dayjs from "dayjs";
import { withApollo } from "react-apollo";
import { useState,useContext } from "react";
import AddEditNotification from "./AddEditNotification";
import UseDeleteNotifications from "../Library/Notification/UseDeleteNotifications";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { UpdateAfterDelete } from "../Library/Notification/UpdateNotification";
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const Notification = ({client}) => {

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showNotifications)
        window.location.href="/";

    const [selectedNotificationID, SetSelectedNotificationID] = useState(null);
    const variables = {
        lang:"cz",
    }

    const {
        error,
        loading, 
        data,
        selectedNotificationIDs,
        SelectNotification
    } = UseGetAllNotifications(variables);

    const {
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteNotifications
    } = UseDeleteNotifications(client,selectedNotificationIDs,(IDs) => {

        UpdateAfterDelete(client,IDs,variables);

    });
    
    return(
        <div className="whole-container" >

            <div className="row" >
                <div className="col-sm one">

                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            Historie notifikací
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => SetSelectedNotificationID(0)}>Přidat</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => ShowDeleteNotification(true)}>Smazat označené</button>
                        </div>
                        <div className="card-body">
                            {error ?
                                <Error text = {error} />
                            :
                                (loading ?
                                    <Loading />
                                :
                                    <>
                                        <div className="data-list">
                                                    
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div className="">Název</div>
                                                    <div className="static text-center">Datum vložení</div>
                                                    <div className="static text-right">Možnosti</div>
                                                    
                                                </div>
                                            </div>

                                            {data && data.map((item,index) => {

                                                var checked = false;
                                                for(let val of selectedNotificationIDs)
                                                {
                                                    if(val == item.notificationID)
                                                        checked = true;
                                                }

                                                return(
                                                    <div key={index} className="data-list-item-content">
                                                        
                                                        <div className="">{item.title}</div>
                                                        <div className="static text-center">{dayjs(item.dateAdd).format("DD.MM.YYYY HH:mm:ss")}</div>
                                                        <div className="static text-right">
                                                            <input className="delete-checked" type="checkbox" name="deleteMarketing[]" checked={checked} onChange = {(e) => SelectNotification(e,item.notificationID)} />
                                                        </div>
                                                        
                                                    </div>
                                                )

                                            })}
                                        </div>

                                        {data && data.length == 0 ?
                                            <NoItems text = "Zatím jste neposlali žádné notifikace" />
                                        :null}
                                    </>
                                )
                            }
                        </div>
                    </div>

                </div>

            </div>

            {selectedNotificationID || selectedNotificationID == 0 ?
                <AddEditNotification
                    selectedNotificationID = {selectedNotificationID}
                    SetSelectedNotificationID = {SetSelectedNotificationID}
                    variables = {variables}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo = {true}
                    text = {"Opravdu je chceš dát pryč ?"}
                    callback = {(action) => DeleteNotifications(action)}
                />
            :null}

        </div> 
    )
}

export default withApollo(Notification);