import {useState,useContext} from 'react';
import {useQuery} from 'react-apollo';
import { GET_ALL_BY_DATE } from '../Queries/operationsLog';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import NoItems from '../../../GlobalComponents/NoItems';
import dayjs from 'dayjs';
import DatePicker from '../../Calendar/Components/Datepicker';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const OperationsLog = () => {

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showOperationLog)
        window.location.href="/";

    const [date,SetDate] = useState(dayjs().format("YYYY-MM-DD"));

    const {data,loading,error} = useQuery(GET_ALL_BY_DATE,{
        variables:{
            withDeleted:false,
            date
        },
        fetchPolicy:"network-only"
    });

    var err;
    if(error)
    {
        const helper = new Helper();
        err = helper.getApolloErrorText(error);
    }

    return(
        <div className="whole-container" >

            <div className="row" >
                <div className="col-sm one">

                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            Provozní deník
                            <div className="ml-auto mr-2">
                                Datum: 
                            </div>
                            <div style={{width:"260px"}}>                            
                                <DatePicker
                                    showPast = {true}
                                    OnChange = {(d,dateDb) => {
                                        SetDate(dateDb)
                                    }}
                                    value = {dayjs(date).format("DD.MM.YYYY")}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {err ?
                                <Error text = {err} />
                            :
                                (loading ?
                                    <Loading />
                                :
                                    <>
                                        <div className="data-list">
                                                    
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div className="static small">Čas</div>
                                                    <div className="static">Uživatel</div>
                                                    <div className="static">Typ</div>
                                                    <div className="">Poznámka</div>
                                                    
                                                </div>
                                            </div>

                                            {data.getAllByDate.map((item,index) => {

                                                return(
                                                    <div key={index} className="data-list-item-content">
                                                        
                                                        <div className="static small">
                                                            {dayjs(item.dateAdd).format("HH:mm:ss")}
                                                        </div>
                                                        <div className="static">{item.customer.astralName}</div>
                                                        <div className="static">{item.operationsLogType.name}</div>
                                                        <div className="">{item.note}</div>
                                                        
                                                    </div>
                                                )

                                            })}
                                        </div>

                                        
                                        {data.getAllByDate.length == 0 ?
                                            <NoItems text = "Tento den nikdo nic nedělal" />
                                        :null}
                                    </>
                                )
                            }
                        </div>
                    </div>

                </div>

            </div>

        </div> 
    )
}

export default OperationsLog;