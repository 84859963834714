import React from 'react';
import {useQuery} from 'react-apollo';
import {GET_CUSTOMER_FOR_DAILY} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import AllCustomerDailyPosts  from './AllCustomerDailyPosts';

const Daily = ({customerID}) => {

    const {data,loading} = useQuery(GET_CUSTOMER_FOR_DAILY,{
        fetchPolicy: 'network-only',
        variables:{
            customerID
        }
    });

    return(
        <div id="daily">

            {loading ?
                <Loading />
            :

                <AllCustomerDailyPosts isVSList = {false} materials = {data.oneCustomer.materials} lastMaterialID = {data.oneCustomer.customerLastMaterial.materialID} guideID = {0} myMotivation = {data.oneCustomer.myMotivation} customerID = {customerID} />
 
            }  

        </div>
    )

}

export default Daily;