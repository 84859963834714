import { useQuery,useMutation } from "react-apollo";
import Helper from "../../../Library/helper";
import Notification from "../../../Library/notification";
import { ADD_EDIT_REVIEW,GET_REVIEWS } from "../Queries/review";
import { useState } from "react";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";

const UseAddEditReview = (selectedReviewID,client,OnCompleted) => {

    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        active:1,
        photo:null,
        mainPhoto:"",
        selectedLang:"",
        langs:[]
    });

    const [AddEditReviewMutation,{error,loading}] = useMutation(ADD_EDIT_REVIEW,{
        onCompleted:(d) => {

            OnCompleted(d.addEditReview);

            const notifyText = (selectedReviewID != 0 ? "Hotovo, je vylepšená" : "Nová recenze je na světě");

            const notify = new Notification();
            notify.setNotification(null,notifyText,true,false,client);

        },
        onError:(err) => {
            const helper = new Helper();
            const notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,client);   
        }
    });

    const AddEditReview = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.text == "" && val.lang == "cz")
                check = false;
        }

        if(check)
        {
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    text:           lang.text,
                    signature:      lang.signature,
                    lang:           lang.lang
                });
            }
            AddEditReviewMutation({
                variables:{
                    reviewID:   selectedReviewID,
                    active:     parseInt(formData.active),
                    photo:      formData.photo,
                    langs:      langs
                }
            })
        }
        else
        {
            const notify = new Notification();
            notify.setNotification(null,"Recenze bez textu není ono.",false,true,client);
        }
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditReview,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditReview;