import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class VideoSlideShowElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">

                            <div className="form-group mr-1">
                                <button onClick={() => cm.addVideoSlideShowItem(lastIndexes)}>Přidat video</button>
                            </div>
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("videoSlideShow",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                                                                        
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"videoSlideShow")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("videoSlideShow",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit tlačítko" title="Odstranit video slide show" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"videoSlideShow",lastIndexes)}}>
                    <div id="faq-element">

                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="form-group">
                                    <label>URL hlavního levého videa</label>
                                    <div>
                                        <input 
                                            type="text" 
                                            name="leftMainVideo"
                                            className="form-control" 
                                            value = {element.leftMainVideo[lang]} 
                                            onChange = {(e) => cm.setElementData(e,lastIndexes,"videoSlideShow",null,null,true)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="form-group">
                                    <label>URL hlavního pravého videa</label>
                                    <div>
                                        <input 
                                            type="text" 
                                            name="rightMainVideo"
                                            className="form-control" 
                                            value = {element.rightMainVideo[lang]} 
                                            onChange = {(e) => cm.setElementData(e,lastIndexes,"videoSlideShow",null,null,true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        {element.items.map((item,index) => (

                            <div className="row" key = {index}>
                                <div className="col-9">
                                    
                                    <label>URL videa</label>
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="url"
                                            className="form-control" 
                                            value = {item.url[lang]} 
                                            onChange = {(e) => cm.setElementData(e,lastIndexes + "-" + index,"videoSlideShowItem",null,null,true)}
                                        />
                                    </div>
                                    
                                </div>
                                <div className="col-3">
                                    
                                    <label>Na šířku</label>
                                    <div className="form-group">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={"orientation-" + index} id={"orientation1-" + index} value="landscape" checked={(item.orientation != "portrait" ? true : false)} onChange={(e) => cm.setElementData("orientation",lastIndexes + "-" + index,"videoSlideShowItem",e.target.value)} />
                                            <label className="form-check-label" htmlFor={"orientation1-" + index}>
                                                Ano
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name={"orientation-" + index} id={"orientation2-" + index} value="portrait" checked={(item.orientation == "portrait" ? true : false)} onChange={(e) => cm.setElementData("orientation",lastIndexes + "-" + index,"videoSlideShowItem",e.target.value)} />
                                            <label className="form-check-label" htmlFor={"orientation2-" + index}>
                                                Ne
                                            </label>
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>

                        ))}
                                                                    
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default VideoSlideShowElement;