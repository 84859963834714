/*
    Výběr veškerých kategorií
*/
import React,{ useState, useContext } from 'react';
import {Query,useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_MEMBERSHIPS} from '../Queries/index.js';
import AddEditMembership from './AddEditMembership';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import { withApollo } from 'react-apollo';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const AllMemberships = (props) => {

    const contextData =  useContext(AuthContext);

    const authLib = new Auth();
    const adminRules = authLib.GetObjFromRules(contextData.rules);
    if(!adminRules.showMembership)
        window.location.href="/";


    const [listVariables,SetListVariables] = useState({
        lang:"cz"
    })

    const [selectedMembershipID,SetMembershipID] = useState(null);

    const {data,loading,error} = useQuery(GET_MEMBERSHIPS,{
        variables:listVariables,
        errorPolicy:"all"
    });


    var err = "";
    if(error)
    {
        const helper = new Helper(); 
        err = helper.getApolloErrorText(error);
    }
                
    return(
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Členství / produkty
                        </div>
                        <div className="card-body">
                             
                            {loading ?
                                <Loading />
                            :
                                <>
                                    {err ?                                            
                                        <Error text={err} />
                                    :
                                        <div>
                                            <div className="data-list">
                                                
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Název členství / produktu</div>
                                                        <div className="text-center">Cena</div>
                                                        <div className="text-right">Možnosti</div>
                                                    </div>
                                                </div>


                                                <div>
                                                    {data.allMemberships && data.allMemberships.map((item,index) => {
                                                        
                                                        return (
                                                            
                                                            <div key={index} className="data-list-item">
                                                                <div className="data-list-item-content categories">
                                                                    <div>
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-center">{item.value}</div>
                                                                    <div className="text-right">
                                                                        <img onClick={() => SetMembershipID(item.membershipDurationID)} className="edit-icon" src={edit} /> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })}
                                                </div>
                                                                
                                            </div>
                                            {data.allMemberships && data.allMemberships.length == 0 &&
                                                <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                            }
                                            {(selectedMembershipID || selectedMembershipID == 0) &&
                                                <AddEditMembership listVariables={listVariables} openCloseModal={SetMembershipID} selectedMembershipID={selectedMembershipID} />
                                            }
                                        </div>
                                    }
                                </>
                            }
                                  
                        </div>
                    </div>
                </div>
            </div> 			
        </div>
    
    );

    

}


export default withApollo(AllMemberships);