import { useQuery } from "react-apollo";
import { GET_NOTIFICATION } from "../../Queries/notification";
import { useState } from "react";
import Helper from "../../../../Library/helper";

const UseGetNotification = (selectedNotificationID,lang,CallBack) => {

    const helper = new Helper();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allGuides, SetAllGuides] = useState([]);

    const {loading,error} = useQuery(GET_NOTIFICATION,{
        variables:{
            notificationID:selectedNotificationID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                title:"",                    
                text:"",                    
            }

            if(selectedNotificationID != 0)
            {
                let langs = helper.CompleteLangsArray(d.NotificationWithLangs.langs,d.allLanguageMutations,emptyLang);
                CallBack({
                    notificationID:               selectedNotificationID,
                    selectedLang:                 lang,
                    sendEmailAboutReadToGuideID:  d.NotificationWithLangs.sendEmailAboutReadToGuideID,
                    isPopUp:                      d.NotificationWithLangs.isPopUp,
                    sendTo:                       1,
                    selectedCustomers:            [],
                    langs:                        langs
                });
            }
            else
            {
                let langs = helper.CompleteLangsArray([],d.allLanguageMutations,emptyLang);

                CallBack({
                    notificationID:0,
                    selectedLang: lang,
                    sendEmailAboutReadToGuideID:0,
                    isPopUp:1,
                    sendTo:1,
                    selectedCustomers:[],
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.allLanguageMutations);
            SetAllGuides(d.allGuides);
        }
    });

    return{
        loading,
        error,
        allLanguageMutations,
        allGuides
    }

}

export default UseGetNotification;