import Modal from 'react-modal';
import UseAddEditNotification from '../Library/Notification/UseAddEditNotification';
import UseGetNotification from '../Library/Notification/UseGetNotification';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { withApollo } from 'react-apollo';
import Helper from '../../../Library/helper';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import SearchInput from '../../../GlobalComponents/SearchInput';
import NoItems from '../../../GlobalComponents/NoItems';
import removeIcon from '../../../Public/Images/remove.svg';
import { UpdateAfterAdd } from '../Library/Notification/UpdateNotification';
import io from "socket.io-client";
import { WEBSOCKET_SERVER } from '../../../Config';

Modal.setAppElement('#root');

const AddEditNotification = ({client,selectedNotificationID,SetSelectedNotificationID,variables}) => {

    const helper = new Helper();

    const {
        loading,
        error,
        formData,
        AddEditNotification,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetCustomer,
        GetCustomers,
        RemoveCustomer
    } = UseAddEditNotification(client,selectedNotificationID,"cz",(d) => OnAddEditSuccess(d));

    const {allLanguageMutations,allGuides,loading:getLoading,error:getError} = UseGetNotification(selectedNotificationID,"cz",(d) => {
        InitFormData({
            selectedLang:                d.selectedLang,
            isPopUp:                     d.isPopUp,
            sendTo:                      d.sendTo,
            sendEmailAboutReadToGuideID: d.sendEmailAboutReadToGuideID,
            selectedCustomers:           d.selectedCustomers,
            allCustomers:                [],
            langs:                       d.langs,
            searchText:                  ""
        })
    });

    const OnAddEditSuccess = async (d) => {

        console.log(d);

        var s = io.connect(WEBSOCKET_SERVER);

        s.emit("new-notification",{
            selectedCustomers: d.selectedCustomers
        });

        SetSelectedNotificationID(null); 
        UpdateAfterAdd(client,d,variables)
    }

    var err = "";
    if(error || getError)
        err = helper.getApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedNotificationID(null)}
            className="Modal__Bootstrap modal-dialog modal-lg"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{(selectedNotificationID && selectedNotificationID != 0 ? "Úprava notifikace" : "Přidání notifikace")}</h4>
                    <div className="ml-auto">
                        <select className="form-control" name="selectedLang" onChange={(e) => SetFormData(e)}>
                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                <option key={index} value={item.suffix}> {item.suffix} </option>
                            ))}
                        </select>
                    </div>
                    <button type="button" className="close ml-3" onClick={() => SetSelectedNotificationID(null)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    {err ?
                        <Error text={err} />
                    :
                        <>
                            {loading || getLoading ?
                                <Loading />
                            : 
                                <>
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">Zobrazit jako vyskakovací okno</label>
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="isPopUp" id="isPopUp1" value="1" checked={(formData.isPopUp == 1 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                        <label className="form-check-label" htmlFor="isPopUp1">
                                                            Ano
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="isPopUp" id="isPopUp2" value="0" checked={(formData.isPopUp == 0 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                        <label className="form-check-label" htmlFor="isPopUp2">
                                                            Ne
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label className="input-label">Název notifikace ({formData.selectedLang})</label>
                                            <div className="form-group">
                                                <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="title" 
                                                    value={GetFormLangValue("title")} 
                                                    onChange={(e) => SetFormLangData(e)} 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-sm-6">
                                            <label className="input-label">Zaslat email průvodci, že si VS zhlédl notifikaci</label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control" 
                                                    name="sendEmailAboutReadToGuideID" 
                                                    value={formData.sendEmailAboutReadToGuideID} 
                                                    onChange={(e) => SetFormData(e)}
                                                >
                                                    <option value={0}>nezasílat nikomu</option>
                                                    {allGuides && allGuides.map((item) => {
                                                        return(
                                                            <option key={item.customerID} value={item.customerID}>
                                                                {item.astralName}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label className="input-label">Zaslat</label>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="sendTo" id="sendTo1" value="1" checked={(formData.sendTo == 1 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                    <label className="form-check-label" htmlFor="sendTo1">
                                                        všem momentálně aktivním VS
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="sendTo" id="sendTo2" value="2" checked={(formData.sendTo == 2 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                    <label className="form-check-label" htmlFor="sendTo2">
                                                        konkrétním VS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.sendTo == "2" ?
                                            <>
                                                <div className="col-12">

                                                    <div className="form-group">
                                                        <SearchInput 
                                                            key={formData.searchText} 
                                                            value={formData.searchText} 
                                                            className="form-control" 
                                                            placeholder="Vyhledejte uživatele" 
                                                            onChange={(text) => GetCustomers(text)} 
                                                        />
                                                    
                                                        {formData.allCustomers.length > 0 ?

                                                            <ul className="customer-list">
                                                                {formData.allCustomers.map((item,index) => 
                                                                    <li 
                                                                        onClick={() => SetCustomer(item)} 
                                                                        key={index}
                                                                    >
                                                                        {item.name} {item.surname} 
                                                                        {(item.astralName != "" ? "(" + item.astralName + ")" : "")} 
                                                                        {(item.name == "" && item.surname == "" ? "(" + item.email + ")" : "")}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        :null}
                                                    </div>
                                                </div>
                                                {formData.selectedCustomers.length > 0 ?
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="data-list">
                                                                                                                
                                                                <div className="data-list-item header">
                                                                    <div className="data-list-item-content">
                                                                        <div className="">Jméno</div>
                                                                        <div className="static text-right">Možnosti</div>
                                                                    </div>
                                                                </div>
                    
                                                                {formData.selectedCustomers.map((item,index) => {
                
                                                                    return(
                                                                        <div key={index} className="data-list-item-content">
                                                                            
                                                                            <div className="">
                                                                                {item.name} {item.surname} {(item.astralName != "" ? "(" + item.astralName + ")" : "")}
                                                                            </div>
                                                                            <div className="static text-right">
                                                                                <img onClick={() => RemoveCustomer(item.customerID)} className="edit-icon" src={removeIcon} /> 
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    )
                    
                                                                })}
                                                            </div>
                    
                                                            {formData.selectedCustomers.length == 0 ?
                                                                <NoItems text = "Zatím nebyl nikdo přidán" />
                                                            :null}
                                                        </div>

                                                    </div>
                                                :null}
                                            </>
                                        :null}

                                        <div className="col-12">
                                            <label className="input-label">Text ({formData.selectedLang})</label>
                                            <div className="form-group">
                                                
                                                <TinyMCEEditor onChange={(content,type) => SetFormLangData(type,content)} initialValue={GetFormLangValue("text")} /> 
                                                                                                
                                            </div>
                                        </div>
                                        
                                    </div>

                                </>
                            }
                        </>
                    }
                </div>
                <div className="modal-footer">
                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => AddEditNotification()}>{(selectedNotificationID && selectedNotificationID != 0 ? "Upravit" : "Přidat")}</button>}
                    <button type="button" className="btn btn-danger" onClick={() => SetSelectedNotificationID(null)}>Zavřít</button>
                </div>
            </div>

        </Modal>
    )
}

export default withApollo(AddEditNotification);