import { useState } from "react";
const UseFormHandle = (formData) => {

    const [newFormData, SetNewFormdata] = useState(formData);

    const SetFormData = (e,val) => {

        var {n,v} = GetEventData(e,val);
        
        SetNewFormdata((prev) => {
            return {...prev,[n]: v};
        });
    }

    const SetCompleteFormData = (data) => {
        SetNewFormdata((prev) => {
            return {...prev,...data};
        });
    }

    const SetFormLangData = (e,val) => {

        var {n,v} = GetEventData(e,val);

        let langs = [...newFormData.langs];
        for(let i in langs)
        {
            if(langs[i].lang == newFormData.selectedLang)
            {
                langs[i] = {...langs[i],[n] : v};
            }
        }

        SetNewFormdata((prev) => {
            return {...prev,langs: langs};
        });
    }

    const OpenLangImage = (e) => {
        
        var param = e.target.name;
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;
            let langs = [...newFormData.langs];

            for(let i in langs)
            {
                if(langs[i].lang == newFormData.selectedLang)
                    langs[i] = {...langs[i],[param] : photo,[param + "Show"] : dataURL};
            }

            SetNewFormdata({...newFormData,langs: langs});

        };

        reader.readAsDataURL(photo);
    }

    const GetFormLangValue = (param) => {

        for(const lang of newFormData.langs){
            if(lang.lang == newFormData.selectedLang){
                if(lang[param] === null){
                    return "";
                }else{
                    return lang[param];
                }
            }
        }
        return "";
    }

    const GetEventData = (e,val) => {

        var n,v;
    
        if(!e.target)
        {
            v = val;
            n = e;
        }
        else
        {
            const t = e.target;
            if(t.type === 'checkbox')
                v = t.checked
            else if(t.type === 'radio')
                v = parseInt(t.value);
            else
                v = t.value;
    
            n = t.name;
        }
    
        return {n,v}
    }

    const SetArrayObjValue = (e,objParam,index,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = {...arr[index],[n]:v}
            
            return {...prevValue,[objParam]: arr}
        })
    }
    
    return{
        formData:newFormData,
        SetFormData,
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        SetArrayObjValue
    }
}

export default UseFormHandle;