import React from 'react';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { withApollo} from 'react-apollo';
import Helper from '../../../Library/helper';
import Modal from 'react-modal';
import UseGetInvoice from '../Library/UseGetInvoice.js';
import UseAddEditInvoice from '../Library/UseAddEditInvoice.js';
import DatePicker,{ registerLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';
import SearchInput from '../../../GlobalComponents/SearchInput.js';
import remove from '../../../Public/Images/remove.svg';
import NoItems from '../../../GlobalComponents/NoItems.js';

registerLocale('cs', cs);

const AddEditInvoice = (props) => {

    const helper = new Helper();
    const {selectedInvoiceID,refetchInvoices,client,ShowAddEditInvoice} = props;
    
    const {
        formData,
        searchText,
        allCustomers,
        InitFormData,
        SetFormData,
        AddEditInvoice,
        GetCustomers,
        SetCustomer,
        AddItem,
        RemoveItem,
        SetArrayObjValue
    } = UseAddEditInvoice(selectedInvoiceID,client,(d) => {
        ShowAddEditInvoice(false,0);
        refetchInvoices();
    });

    const {
        loading,
        error,
        allDomains,
        allCountries
    } = UseGetInvoice(selectedInvoiceID,(d) => {
        InitFormData(d);
    });

    var err = "";
    if(error)
        err = helper.getApolloErrorText(err);
    
    return(

        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => ShowAddEditInvoice(false,0)}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Přidat fakturu</h4>
                    <button type="button" className="close" onClick={() => ShowAddEditInvoice(false,0)}>
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        {!loading ?
                                                            
                            <div>

                                {err ?
                                    <Error text={err} />
                                :null}

                                <div className="row">

                                    <div className = "col-3">

                                        <label className="input-label">Zaslat fakturu emailem</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="sendInvoiceByEmail" id="sendInvoiceByEmail1" value="1" checked={(formData.sendInvoiceByEmail == 1 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                <label className="form-check-label" htmlFor="sendInvoiceByEmail1">
                                                    Ano
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="sendInvoiceByEmail" id="sendInvoiceByEmail2" value="0" checked={(formData.sendInvoiceByEmail == 0 ?  true : false)} onChange={(e) => SetFormData(e)} />
                                                <label className="form-check-label" htmlFor="sendInvoiceByEmail2">
                                                    Ne
                                                </label>
                                            </div>
                                        </div>
                                    
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-6">
                                        <label className="input-label">Pod kterou doménu zaevidovat fakturu</label>
                                        <div className="form-group">
                                            <select className="form-control" name="domainID" value={formData.domainID} onChange={(e) => SetFormData(e)}>
                                                <option value={0} key={0}>--- vyberte ---</option>
                                                {allDomains.map((item) => {
                                                    return (
                                                        <option value={item.domainID} key={item.domainID}>{item.domainName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <label className="input-label">Datum vytvoření</label>
                                        <div className="form-group">
                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.dateAdd} onChange={date => SetFormData("dateAdd",date)} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label className="input-label">Datum splatnosti</label>
                                        <div className="form-group">
                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.dueDate} onChange={date => SetFormData("dueDate",date)} />
                                        </div>
                                    </div>

                                </div>

                                <hr />
           
                                <div className="form-group">
                                    <label className="input-label">Vyhledat zákazníka pro rychlejší vyplnění údajů</label> 
                                    <SearchInput 
                                        key={searchText} 
                                        value={searchText} 
                                        className="form-control" 
                                        placeholder="Vyhledejte uživatele" 
                                        onChange={(text) => GetCustomers(text)} 
                                    />
                                
                                    {allCustomers.length > 0 ?

                                        <ul className="customer-list">
                                            {allCustomers.map((item,index) => 
                                                <li 
                                                    onClick={() => SetCustomer(item)} 
                                                    key={index}
                                                >
                                                    {item.name} {item.surname} 
                                                    {(item.astralName != "" ? "(" + item.astralName + ")" : "")} 
                                                    {(item.name == "" && item.surname == "" ? "(" + item.email + ")" : "")}
                                                </li>
                                            )}
                                        </ul>
                                    :null}
                                </div>

                                <div className="row">

                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Jméno</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Příjmení</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="surname" value={formData.surname} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Email</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="email" value={formData.email} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Telefon</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="tel" value={formData.tel} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Ulice</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="street" value={formData.street} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Město</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="city" value={formData.city} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">PSČ</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="zip" value={formData.zip} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label className="input-label">Stát</label>
                                        <div className="form-group">
                                            <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => SetFormData(e)}>
                                                {allCountries.map((item) => {
                                                    return (
                                                        <option value={item.countryID} key={item.countryID}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-4">
                                        <label className="input-label">Společnost</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="company" value={formData.company} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <label className="input-label">IČ</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="ic" value={formData.ic} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <label className="input-label">DIČ</label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="dic" value={formData.dic} onChange={(e) => SetFormData(e)} />
                                        </div>
                                    </div>

                                </div>

                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                        Položky
                                        <div className="ml-auto">
                                            <button 
                                                onClick = {() => AddItem()}
                                                className="btn btn-primary btn-thiner"
                                            >Přidat položku</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="data-list mt-2">

                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>Název položky</div>
                                                    <div className="static text-center">Počet</div>
                                                    <div className="static text-center">Cena</div>
                                                    <div className="static smallest"></div>
                                                </div>
                                            </div>
                                            {formData.items.length > 0 ?
                                                <div className="data-list-item">
                                                    {formData.items.map((item,index) => (
                                                        <div key={index} className="data-list-item-content">
                                                            <div>
                                                                <input 
                                                                    value = {item.text}
                                                                    className="form-control" 
                                                                    type="text" 
                                                                    name = "text"
                                                                    onChange = {(e) => SetArrayObjValue(e,"items",index)}
                                                                />
                                                            </div>
                                                            <div className="static text-center">
                                                                <input 
                                                                    value = {item.count}
                                                                    className="form-control" 
                                                                    name = "count"
                                                                    type="number" 
                                                                    onChange = {(e) => SetArrayObjValue(e,"items",index)}
                                                                />
                                                            </div>
                                                            <div className="static text-right">
                                                                <input 
                                                                    value = {item.amount}
                                                                    className="form-control" 
                                                                    name = "amount"
                                                                    type="number" 
                                                                    onChange = {(e) => SetArrayObjValue(e,"items",index)}
                                                                />
                                                            </div>
                                                            <div className="static smallest text-right">
                                                                <img onClick={() => RemoveItem(index)} className="remove-icon" src={remove} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                                                                        
                                                </div>
                                            :
                                                <NoItems text="Zatím bez položek." />
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>   
                           
                        :
                        <Loading />  
                        }  

                    </div>

                </div>
                <div className="modal-footer"> 
                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => AddEditInvoice()}>{selectedInvoiceID != 0 ? "Upravit" : "Přidat"}</button>
                    <button type="button" className="btn btn-danger" onClick={() => ShowAddEditInvoice(false,0)}>Zavřít</button>
                </div>
            </div>
        </Modal>
                        

    );

    
}

export default withApollo(AddEditInvoice);
