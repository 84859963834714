import { useEffect, useState } from "react";
import dayjs from 'dayjs';

const UseCalendar = (GetBlockedDays,selDate,showPast,OnClickOnBlockedDay,OnSelectDate) => {

    var selectedD;
    if(selDate)
        selectedD = selDate.split("."); 

    const todayMonth = dayjs().format("M") - 1;   
    const todayYear  = dayjs().format("YYYY"); 
    const todayDay  = dayjs().format("D");

    const months = ["leden","únor","březen","duben","květen","červen","červenec","srpen","září","říjen","listopad","prosinec"];

    const [loading,SetLoading] = useState(false);
    const [blockedDays,SetBlockedDays] = useState([]);
    const [selectedMonth, SetSelectedMonth] = useState((selectedD ? selectedD[1]-1 : dayjs().date(1).$M));
    const [selectedYear, SetSelectedYear] = useState((selectedD ? selectedD[2] : dayjs().date(1).$y));
    const [selectedDate, SetSelectedDate] = useState((selectedD ? selectedD[2] + "-" + selectedD[1] + "-" + selectedD[0] : null));
    const [days, SetDays] = useState([]);

    useEffect(() => {
        GenerateMonthdays(selectedDate,selectedMonth,selectedYear,blockedDays);
    },[selectedDate])

    useEffect(() => {
        ShowCalendarWithBlockDays(selectedDate,selectedMonth,selectedYear);
    },[])
    
    const SetMonth = (month) => {

        SetSelectedDate((prevSelectedDate) => {

            SetSelectedYear((prevVal) => {

                var newYear = prevVal;

                if(prevVal > 1)
                {
                    
                    if(month < 0)
                    {
                        month = 11; 
                        newYear = parseInt(prevVal) - 1;
                    }

                    if(month > 11)
                    {
                        month = 0;
                        newYear = parseInt(prevVal) + 1;
                    }

                    SetSelectedMonth(month); 
                    ShowCalendarWithBlockDays(prevSelectedDate,month,newYear);

                    return newYear; 
                    
                }
                else
                    return prevVal;
            });

            return prevSelectedDate;
        });
    }

    const ShowCalendarWithBlockDays = async (selectedDate,month,newYear) => {

        var blDays = [];
        if(GetBlockedDays)
            blDays = await GenerateBlockedDays(month,newYear); 

        GenerateMonthdays(selectedDate,month,newYear,blDays);
    }

    const GenerateBlockedDays = async (month,year) => {

        SetLoading(true);

        const blockedDays = await GetBlockedDays(parseInt(month),parseInt(year));
        SetBlockedDays(blockedDays);

        SetLoading(false);

        return blockedDays;
    }

    const SelectDate = (dayObj) => {

        if(OnClickOnBlockedDay && dayObj.isBlocked)
        {
            var dateObj = dayjs().set('year', selectedYear).set('month', selectedMonth).set('date', dayObj.day);

            OnClickOnBlockedDay(dateObj.format("YYYY-MM-DD"),dayObj.customer);
        }
        else if(!dayObj.isBlocked && !dayObj.inPast && dayObj.day != "")
        {
            var dateObj = dayjs().set('year', selectedYear).set('month', selectedMonth).set('date', dayObj.day);

            SetSelectedDate(dateObj.format("YYYY-MM-DD"));
            OnSelectDate(dateObj.format("DD.MM.YYYY"),dateObj.format("YYYY-MM-DD"),dayObj.day,selectedMonth,selectedYear);
        }
    }

    const GenerateMonthdays = (selectedDate,month,year,blockedDays) => {

        var selectedDay   = null;
        var selectedMonth = null;
        var selectedYear  = null;

        if(selectedDate)
        {
            var selDate = selectedDate.split("-");
            selectedDay   = parseInt(selDate[2]);
            selectedMonth = parseInt(selDate[1]) - 1;
            selectedYear  = parseInt(selDate[0]);
        }

        // číslo prvního dne v měsíci v rámci prvního týdne - jestli se jedná o pondělí = 1, úterý = 2,..
        var dayNumberOfFirstDay = dayjs().set('year', year).set('month', month).set('date', 1).date(1).$W;
       
        //počet dní v měsíci
        var daysInMonth = dayjs().set('year', year).set('month', month).set('date', 1).daysInMonth();

        //celkový počet řádků v kalendáří je počet dní v měsící + 
        //počet dní před prvním dnem měsíce v rámci daného týdne dělěno 7 zaokrouhleno nahoru +
        //počet dní které dopní celý poslední týden v kterém daný měsíc končí
        var totalDays = Math.ceil((daysInMonth + (dayNumberOfFirstDay - 1))); 
        var rest = totalDays % 7;

        if(rest > 0) rest = 7 - rest;
        totalDays = totalDays + rest;

        var daysRows = [];
        var days = [];

        var blDays = GetBlockedDaysInObj(blockedDays);

        for(let i = 1; i <= totalDays; i++)
        {            
            if(i < dayNumberOfFirstDay)
                days.push({
                    day:""
                });
            else if(i <= daysInMonth + (dayNumberOfFirstDay - 1))
            {
                var isBlocked  = false;
                var isSelected = false;
                var customer   = null;
                var title      = "";
                var inPast = false;
                var newDay = i - (dayNumberOfFirstDay - 1);

                for(let val of blDays)
                {
                    if(val.day == newDay && month == val.month && val.year == year)
                    {
                        isBlocked = true;  
                        if(val.customer)
                        {
                            title = val.customer.name + " " + val.customer.surname;
                            customer = val.customer;
                        }
                        else
                            title = "Ruční blokace"
                    }
                }

                if(selectedDay && selectedDay == newDay && month == selectedMonth && selectedYear == year)
                    isSelected = true;

                if(todayMonth == month && todayYear == year && newDay <= todayDay && !showPast)
                    inPast = true;

                    

                days.push({
                    day:newDay,
                    isBlocked,
                    isSelected,
                    inPast,
                    title,
                    customer
                });
            }
            else
                days.push({
                    day:""
                });
            
            if(i % 7 == 0)
            {
                daysRows.push(days);
                days = [];
            }
        }

        SetDays(daysRows);
    }

    const GetBlockedDaysInObj = (blockedDays) => {

        var blDays = [];
        for(let val of blockedDays)
        {
            var blDayObj = val.date.split("-");
            blDays.push({
                day:        parseInt(blDayObj[2]),
                month:      parseInt(blDayObj[1] - 1),
                year:       parseInt(blDayObj[0]),
                customer:   val.customer
            })
        }

        return blDays;
    }

    return{
        days,
        month:months[selectedMonth],
        year:selectedYear,
        selectedMonth,
        todayMonth,
        todayYear,
        loading,
        SetMonth,
        SelectDate
    }
    
}

export default UseCalendar;