/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {UPDATE_MATERIAL_PRIORITY,GET_MATERIALS} from '../Queries/index.js';
import SubMaterial from './SubMaterial';
import AddEditMaterial from './AddEditMaterial';
import Helper from '../../../Library/helper';

const INITIAL_STATE = {
    showDeleteMaterialNotifi : false,
    showAddToVs:false,
    showDoNotDelete:false,
    showAddMaterial:false,
    selectedMaterialID:0,
    materialID:0,
    parentID:0,
    addToVs:null,
    deleteMaterial:null,
    contentManagerMaterialID:0
}

class AllMaterials extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        this.deleteMaterial = this.deleteMaterial.bind(this);
        this.delMaterial    = this.delMaterial.bind(this);
        this.showDoNotDel   = this.showDoNotDel.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.ShowAddToVs    = this.ShowAddToVs.bind(this);
        this.AddToVs        = this.AddToVs.bind(this);
    }

    /**
     * funkce přebírá hodnoty z vnoření komponenty deleteMaterial
     * @param {*} deleteMaterial funkce, která posílá data na server
     * @param {*} materialID ID kategorie
     */
    
    deleteMaterial(deleteMaterial,materialID,parentID,subMaterial){
        
        if(subMaterial){
            this.setState({
                showDoNotDelete:true
            });
        }else{
            this.setState({
                showDeleteMaterialNotifi:true,
                deleteMaterial,
                materialID,
                parentID,
            });
        }
        
        
    }

    delMaterial(action){

        if(action){
            this.state.deleteMaterial({
                variables:{
                    materialID: this.state.materialID,
                    parentID: this.state.parentID
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDoNotDel(action){
        this.setState({
            showDoNotDelete:action
        });
    }

    ShowAddToVs(addToVs,materialID,parentID){
        this.setState({
            showAddToVs:true,
            addToVs,
            selectedMaterialID:materialID,
            parentID
        });
    }

    AddToVs(action)
    {
        if(action)
        {
            this.state.addToVs({
                variables:{
                    materialID: this.state.selectedMaterialID,
                    parentID:  this.state.parentID
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    openCloseModal(type,materialID){
        this.setState({
            showAddMaterial:type,
            selectedMaterialID:materialID
        })
    }

    render(){
        
        return(

            <Query query={GET_MATERIALS} errorPolicy="all" variables={{lang:"cz"}}>
                {
                    ({data,loading,error,refetch }) => {
                        if(error){
                            const helper = new Helper(); 
                            return (<Error text={error} />);
                        }
                        if(loading) return (<Loading />);
                        
                        return(
                            <div>
                                <div className="data-list">
                                    
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Název</div>
                                                <div className="text-center">Aktivní</div>
                                                <div className="text-center">Přidat při registraci</div>
                                                <div className="text-right">Možnosti</div>
                                            </div>
                                        </div>

                                        <Mutation 
                                            mutation={UPDATE_MATERIAL_PRIORITY} 
                                            update={() => {
                                                refetch();
                                            }}
                                        >
                                        {
                                            (updateMaterialPriority,{error}) => {

                                                if(error){
                                                    const helper = new Helper(); 
                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                }

                                                return (
                                                    <SubMaterial openCloseModal={this.openCloseModal} openContentManager = {this.openContentManager} deleteMaterial={this.deleteMaterial} updateMaterialPriority={updateMaterialPriority} materials={data.allMaterials} level={0} ShowAddToVs = {this.ShowAddToVs} />
                                                )
                                        }}
                                        </Mutation>
                                    
                                </div>
                                {data.allMaterials && data.allMaterials.length == 0 &&
                                    <NoItems text={"Momentálně se zde nenachází žádné materiály."} />
                                }
                                {this.state.showDeleteMaterialNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat tento odkaz ?'} callback={this.delMaterial} /> }
                                {this.state.showDoNotDelete && <ModalNotification yesNo={false} text={'Tento odkaz nelze smazat, protože obsahuje subodkazy. Smažte nejdříve dané subodkazy.'} callback={this.showDoNotDel} /> }
                                {this.state.showAddToVs && <ModalNotification yesNo={true} text={'Opravdu chceš tento materiál přidat všem Vládcům?'} callback={this.AddToVs} /> }
                                {this.state.showAddMaterial &&
                                    <AddEditMaterial openCloseModal={this.openCloseModal} selectedMaterialID={this.state.selectedMaterialID} />
                                }

                            </div>
                        );
                    }
                }
            </Query>
        
        );

    }

}


export default AllMaterials;