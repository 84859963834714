/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
 import gql from 'graphql-tag';

 /**
  * vložení odkazu
  */
 export const ADD_EDIT_EVS_DATA = gql`
     mutation AddEditEvsData(
         $data:EvsDataLangsInput
     ){
        addEditEvsData(data:$data)
     }
 `;

  /**
  * vložení odkazu
  */
  export const ADD_TO_MULTIPLE_CUSTOMER_EVS_POINTS = gql`
  mutation AddToMultipleCustomersEVSPoint(
    $selectedCustomers:[ID], 
    $info:String, 
    $points:Int, 
    $month:Int, 
    $year:Int
  ){
    addToMultipleCustomersEVSPoint(
        selectedCustomers:$selectedCustomers,
        info:$info,
        points:$points,
        month:$month,
        year:$year,
    )
  }
`;

 /**
 *  Data pro jednu kategorii
 */

export const GET_EVS_DATA = gql`
    query EvsDataWithLangs{
        evsDataWithLangs{
            evsDataID
            langs{
                lang
                mainText
                description
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;


/**
 * vložení kategorie
 */
 export const ADD_EDIT_EVS_MONTH_DATA = gql`
 mutation AddEditEvsMonthData($evsMonthDataID:ID,$month:Int,$year:Int,$numberOfWinners:Int, $langs:[EvsMonthDataInput]){
    addEditEvsMonthData(evsMonthDataID:$evsMonthDataID, month:$month,year:$year,numberOfWinners:$numberOfWinners,langs:$langs){
         evsMonthDataID
         month
         year
         numberOfWinners
         error
    }
 }
`;

/**
*  Data pro jednu kategorii
*/

export const GET_EVS_MONTH_DATA = gql`
 query EvsMonthDataWithLangs($evsMonthDataID: ID){
    evsMonthDataWithLangs(evsMonthDataID:$evsMonthDataID){
        evsMonthDataID
        month
        year
        numberOfWinners
        langs{
            lang
            text
        }    
    }
    allLanguageMutations(onlyEnabled:true){
         languageID
         title
         suffix
         file
         generateNiceTitle
         langTitle
         decimal
         priority
         main
         currencyTitle
     }
 }
`;

/**
*  Seznam veškerých kategorií
*/
export const GET_EVS_MONTH_All_DATA = gql`
 query AllEvsMonthData($lang: String!){
    evsMonthData(lang: $lang){
         evsMonthDataID
         month
         year
         numberOfWinners
         text
         lang
     }
 }
`;

/**
* Smazání kategorie.
*/
export const DELETE_EVS_MONTH_DATA = gql`
 mutation deleteEvsMonthData($evsMonthDataIDs:ID!) {
    deleteEvsMonthData(evsMonthDataIDs:$evsMonthDataIDs)
 }
`;

export const DELETE_EVS_MONTH_DATA_IMAGE = gql`
 mutation deleteEvsMonthDataImage($evsMonthDataImagesID:[ID],$evsMonthDataID:ID) {
    deleteEvsMonthDataImage(evsMonthDataImagesID:$evsMonthDataImagesID,evsMonthDataID:$evsMonthDataID)
 }
`;


/**
* veškeré fotky daného produktu
*/
export const GET_EVS_MONTH_DATA_IMAGES = gql`
 query EvsMonthDataImages($evsMonthDataID: ID){
    evsMonthDataImages(evsMonthDataID:$evsMonthDataID){
        evsMonthDataImageID
        photo
    }
 
 }
`; 

/**
* Upload obrázku pro produkt
*/
export const UPLOAD_EVS_MONTH_DATA_IMAGES = gql`
    mutation uploadEvsMonthDataImages($files: [Upload!],$evsMonthDataID:ID!) {
        uploadEvsMonthDataImages(files: $files,evsMonthDataID:$evsMonthDataID){
            evsMonthDataImageID
            photo
        } 
    }
`; 

