import { useState } from "react";
import Calendar from "./Calendar";

const DatePicker = ({OnChange,value,GetBlockedDays,showPast}) => {

    const [showCalednar, SetShowCalendar] = useState(false);
    const [date,SetDate] = useState((value ? value : ""));

    const SelectDate = (dateString,dateDb) => {
        SetDate(dateString);
        OnChange(dateString,dateDb);
        SetShowCalendar(false);
    }

    const OverlayClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        SetShowCalendar(false);
    }

    return(
        <div className="reservation-datepicker">
            <input className="form-control" placeholder = {"Vyberte datum"} type = "text" value = {date} onClick = {() => SetShowCalendar(true)} onChange = {(e) => e.preventDefault()} />
            {showCalednar ?
                <>
                    <div className="pop-up-calendar">
                        <Calendar 
                            showLegend      = {false} 
                            GetBlockedDays  = {GetBlockedDays} 
                            OnSelectDate    = {SelectDate} 
                            selectedDate    = {date}
                            showPast        = {showPast}
                        />
                    </div>
                    
                    <div onClick = {(e) => OverlayClick(e)} className="pop-up-calendar-overlay"></div>
                </>
            :null}

        </div>
    )
}

export default DatePicker;