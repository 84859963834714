/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_MEMBERSHIP = gql`
    mutation AddEditMembership($membershipDurationID:ID,$thanksLinkID:ID, $langs:[MembershipLangsInput]){
        addEditMembership(membershipDurationID:$membershipDurationID, thanksLinkID:$thanksLinkID,langs:$langs){
            membershipDurationID
            name
            lang
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_MEMBERSHIP = gql`
    query MembershipWithLangs($membershipDurationID: ID!){
        membershipWithLangs(membershipDurationID:$membershipDurationID){
            membershipDurationID
            thanksLinkID
            langs{
                lang
                emailText
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allLinks(lang:"cz"){
            linkID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_MEMBERSHIPS = gql`
    query AllMemberships($lang: String!){
        allMemberships(lang: $lang,onlyActive:false){
            membershipDurationID
            name
            value
        }
    }
`;