import gql from 'graphql-tag';

export const ADD = gql`
    mutation Add(
        $operationsLogTypeID:ID
        $customerID:ID	
        $note:String
    ){
        add(
            operationsLogTypeID:$operationsLogTypeID,
            customerID: $customerID, 
            note:$note
        )
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_ALL_BY_DATE = gql`
    query GetAllByDate(
        $withDeleted: Boolean,
        $date:String
    ){
        getAllByDate(
            withDeleted:$withDeleted,
            date:$date
        ){
            operationsLogID
            operationsLogTypeID
            customerID
            note
            dateAdd
            operationsLogType{
                name
            }
            customer{
                astralName
                name
                surname
            }
        }
    }
`;