/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

/**
 * vložení a editace zákazníka
 */
export const ADD_EDIT_CUSTOMER = gql`
    mutation AddEditCustomer(
        $customerID:ID,
        $guideID:ID,
        $customerTypeID:ID,
        $name: String,
        $surname: String,
        $astralName: String,
        $company: String,
        $email: String,
        $tel: String,
        $bankAccount:String,
        $swift:String,
        $iban:String,
        $street: String,
        $city: String,
        $zip: String,
        $countryID:ID,
        $domainID:ID,
        $autoPayments:Int,
        $membershipDuration:ID,
        $description: String,
        $emailTextToGuide:String,
        $firstPaymentUrl:String
        $selectedMaterials:[ID],
        $affiliateProfit:Float,
        $affiliateProfit2:Float
    ){
        addEditCustomer(
            customerID:          $customerID,
            guideID:             $guideID,
            customerTypeID:      $customerTypeID,
            name:                $name,
            surname:             $surname,
            astralName:          $astralName,
            company:             $company,
            email:               $email,
            tel:                 $tel,
            bankAccount:         $bankAccount,
            swift:               $swift,
            iban:                $iban,
            street:              $street,
            city:                $city,
            zip:                 $zip,
            countryID:           $countryID,
            domainID:            $domainID,
            description:         $description,
            emailTextToGuide:    $emailTextToGuide,
            firstPaymentUrl:     $firstPaymentUrl,
            autoPayments:        $autoPayments,
            membershipDuration:  $membershipDuration,
            selectedMaterials:   $selectedMaterials,
            affiliateProfit:     $affiliateProfit,
            affiliateProfit2:    $affiliateProfit2
        ){
            customerID
            customerTypeID
            name
            surname
            astralName
            company
            email
            tel 
            membershipTo
            membershipDuration
            autoPayments
            membership{
                name
            } 
            customerType{
                name
            }       
        }
    }
`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER_BY_EMAIL = gql`
    query CustomerByEmail($email: String!,$customerID:ID!){
        customerByEmail(email:$email,customerID:$customerID){
            customerID
            membershipTo
        }
    }
`;

export const GET_CUSTOMERS_CREDIT_CARDS = gql`
query AllCustomerCreditCards($email: String!,$customerID:ID!){
    allCustomerCreditCards(email:$email,customerID:$customerID){
        name
        token
    }
}
`;

export const DELETE_CUSTOMERS_CREDIT_CARD = gql`
    mutation DeleteCustomersCreditCard($token: String!,$customerID:ID!){
        deleteCustomersCreditCard(token:$token,customerID:$customerID)
    }
`;

export const HAS_CUSTOMER_AFFILIATE = gql`
    query HasCustomerAffiliate($customerID:ID!){
        hasCustomerAffiliate(customerID:$customerID){
            domainID
        }
    }
`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER = gql`
    query Customer($customerID: ID!,$lang: String!){
        oneCustomer(customerID:$customerID){
            customerID
            guideID
            customerTypeID
            questionnaireID
            name
            surname
            astralName
            company
            email
            tel
            bankAccount
            swift
            iban
            street
            city
            zip
            countryID
            domainID
            description
            emailTextToGuide
            firstPaymentUrl
            autoPayments
            membershipDuration
            affiliateProfit
            affiliateProfit2
            membershipTo
            materials{
                materialID
            }
        }
        allGuides{
            customerID
            name
            surname
            astralName
        }
        allCountries{
            countryID
            name
            defaultSelected
        }
        allAdminMembershipDurations(lang:$lang,onlyActive:false){
            membershipDurationID
            name
            value
        }
        allDomains{
            domainID
            domainName
        }
        allCustomerTypes{
            customerTypeID
            name
        }
        
    }
`;

export const GET_CUSTOMER_PAYMENTS = gql`
    query Customer($customerID: ID){
        oneCustomer(customerID:$customerID){
            customerID
            customerPayments{
                customerPaymentID
                dateAdd
            }
        }        
    }
`;

export const GET_CUSTOMER_MATERIAL_DATA = gql`
    query Customer($lang: String!){
        allMaterials(lang:$lang){
            materialID
            name
            parentID
            subMaterial{
                materialID
                name
                parentID
                subMaterial{
                    materialID
                    name
                    parentID
                    subMaterial{
                        materialID
                        name
                        parentID
                        subMaterial{
                            materialID
                            name
                            parentID
                            subMaterial{
                                materialID
                                name
                                parentID
                                subMaterial{
                                    materialID
                                    name
                                    parentID
                                }
                            }
                        }
                    }
                }
            }
        }
                
    }
`;

/**
 *  Seznam veškerých zákazníků
 */
export const GET_CUSTOMERS = gql`
    query AllCustomers($offset:Int, $limit: Int,$text:String){
        allCustomers(offset:$offset,limit:$limit,text:$text){
            customerID
            customerStripeID
            countryID
            name
            surname
            astralName
            company
            email
            tel 
            street
            city
            zip
            ic
            dic
            membershipTo
            membershipDuration
            autoPayments
            customerTypeID
            membership{
                name
            }
            customerType{
                name
            }
        }
        
    }
`;

export const GET_CUSTOMERS_WITH_AFF_URL = gql`
    query AllCustomers($offset:Int, $limit: Int,$text:String){
        allCustomers(offset:$offset,limit:$limit,text:$text){
            customerID
            name
            surname
            email
            astralName
            affiliateUrl{
                customerAffiliateUrlID
                affiliateID
                url
            }
        }
        
    }
`;

/**
 * Smazání zákazníka.
 */
export const DELETE_CUSTOMER = gql`
    mutation deleteCustomers($customerID:ID!) {
        deleteCustomers(customerID:$customerID)
    }
`;

/**
 *  seznam veškerých faktur zákazníka
 */

export const GET_CUSTOMERS_INVOICES = gql`
    query CustomerInvoices($offset:Int, $limit: Int,$customerID:ID){
        allCustomerInvoices(offset:$offset,limit:$limit,customerID:$customerID){
            invoiceID
            invoiceNumber
            dateAddStr
            totalPrice
            dueDateStr
            items{
                invoiceItemID
                text
                count
                amount
            }
        } 
    }
`;

export const ADD_PAYMENT = gql`
    mutation AddManualyCustomerPayment($customerID:ID,$paymentDate:String,$paymentItems:[PaymentItemsInput]){
        addManualyCustomerPayment(customerID:$customerID,paymentDate:$paymentDate,paymentItems:$paymentItems)
    }
`;

export const GET_CUSTOMERS_EVS_POINTS = gql`
    query CustomerEVSPoints($month:Int, $year: Int,$customerID:ID){
        allCustomerEVSPoints(month:$month,year:$year,customerID:$customerID){
            customerEVSPointID
            dateAdd
            info
            points
        } 
    }
`;

export const DELETE_CUSTOMER_EVS_POINTS = gql`
    mutation deleteCustomerEVSPoint($customerEVSPointIDs:ID!) {
        deleteCustomerEVSPoint(customerEVSPointIDs:$customerEVSPointIDs)
    }
`;

export const ADD_EDIT_CUSTOMER_EVS_POINTS = gql`
    mutation AddEditCustomerEVSPoint(
        $customerEVSPointID:ID,
        $customerID:ID,
        $info:String,
        $points:Int,
        $month:Int,
        $year:Int   
    ){
        addEditCustomerEVSPoint(
            customerEVSPointID:  $customerEVSPointID,
            customerID:          $customerID,
            info:                $info,
            points:              $points,
            month:               $month,
            year:                $year
        ){
            customerEVSPointID
            dateAdd
            info
            points      
        }
    }
`;

export const GET_CUSTOMER_EVS_POINT = gql`
    query CustomerEVSPoint($customerEVSPointID:ID){
        customerEVSPoint(customerEVSPointID:$customerEVSPointID){
            customerEVSPointID
            info
            points
        } 
    }
`;

export const REMOVE_MEMBERSHIP = gql`
    mutation RemoveMembership(
        $customerID:ID 
        $isTrialPeriod:Boolean
    ){
        removeMembership(
            customerID:  $customerID,
            isTrialPeriod:$isTrialPeriod
        ){
            customerStripeID
            autoPayments
        }
    }
`;

export const GET_CUSTOMER_QUESTIONNAIRE_STEPS = gql`
    query GetCompleteQuestionnaireSteps($lang:String,$customerID:ID,$questionnaireID:ID){
        oneCustomer(customerID:$customerID){
            hasCompletedQuestionnaire
        }
        getCompleteQuestionnaireSteps(lang:$lang,withDeleted:true,questionnaireID:$questionnaireID){
            questionnaireStepID
            questionTypeID
            lang
            question
            items{
                questionnaireStepItemID
                text
            }
        } 
        customerQuestionnaireSteps(customerID:$customerID,questionnaireID:$questionnaireID){
            questionnaireStepID
            text
            checkboxQuestionnaireStepItemIDs
            radioQuestionnaireStepItemID
        }

    }
`;

export const GET_CUSTOMER_FOR_DAILY = gql`
    query Customer($customerID: ID!){
        oneCustomer(customerID:$customerID){
            customerID
            materials{
                materialID
            }
            customerLastMaterial{
                materialID
            }
        }        
    }
`;

export const GET_CUSTOMER_DAILY_DATA = gql`
    query GetCustomerDailyData($customerID:ID,$offset:Int,$limit:Int,$messageType:Int){
        
        allCustomerDailyPosts(customerID:$customerID,offset:$offset,limit:$limit,messageType:$messageType){
            customerDailyPostID
            guideID
            dateAdd
            text
            readByGuide
            customerID
            guide{
                name
                surname
                astralName
            }
        }
        allCustomerDailyPostsCount(customerID:$customerID,messageType:$messageType)
        allMessageTypeNotReadMessagesCount(customerID:$customerID){
            messages
            dreams
            sync
            intangibleExperiences
            negativCharacteristics
            positiveCharacteristics
            charismaticEssence
            joys
            transformation
            lifeValues
            meaningOfLife
            wishes
            dreamEightPlusOne
            actor
            personalNotes
        }
        customerContext{
            customerTypeID
            customerID
            myMotivation
        }
    }
`;
