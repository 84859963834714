/*
    Přidání kategorie
*/
import React,{Fragment,useEffect } from 'react';
import {useMutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_COURSE,GET_COURSES} from '../Queries/course.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import UseAddCourse from '../Library/useAddCourse';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic.js';

const AddEditCourse = ({listVariables,selectedCourseID,OpenAddEditCourse,client}) => {

    
    const [AddEditCourse,{loading:addLoading, error:addError}] = useMutation(ADD_EDIT_COURSE,{

        update: async (cache, response) => {
            
            const { allCourses } = cache.readQuery({ query: GET_COURSES,variables:listVariables });
            if(!(selectedCourseID && selectedCourseID != 0))
            {
                console.log(response);
                cache.writeQuery({ 
                    query:GET_COURSES,
                    variables:listVariables,
                    data:{
                        allCourses: [response.data.addEditCourse,...allCourses]
                    } 
                });

            }else{

                var arr = [...allCourses];
                arr.forEach((item,index) => {
                    
                    if(item.courseID == response.data.addEditCourse.courseID)
                    {
                        arr[index].courseID = response.data.addEditCourse.courseID;
                        arr[index].name     = response.data.addEditCourse.name;
                    }
                });
                cache.writeQuery({ 
                    query:GET_COURSES,
                    variables:listVariables,
                    data:{
                        allCourses: arr
                    } 
                });
            }

            let notify = new Notification();

            if(selectedCourseID && selectedCourseID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
            else notify.setNotification(cache,'Úspěšně přidáno',true,false);

        },
        onCompleted: () => {
            OpenAddEditCourse(false);
        }
    });

    const {
        allLanguageMutations,
        formData,
        allQuestionnaires,
        allGuides,
        loading,
        error,
        GetData,
        SetFormDataValue,
        SetFormDataLangValue,
        GetLangValue,
        AddCourse
    } = UseAddCourse(AddEditCourse,selectedCourseID,client);

    useEffect(() => {
        GetData();
    },[])

    var err = "";

    if(addError || error){
        const helper = new Helper(); 
        err = helper.getApolloErrorText(addError || error);
    }
  
    return(

        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => OpenAddEditCourse(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(selectedCourseID && selectedCourseID != 0 ? "Úprava kurzu" : "Přidání kurzu")}</h4>
                    <div className="ml-auto">
                        <select value={formData.selectedLang} className="form-control" name="selectedLang" onChange={(e) => SetFormDataValue(e)}>
                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                <option key={index} value={item.suffix}> {item.suffix} </option>
                            ))}
                        </select>
                    </div>
                    <button type="button" className="close ml-3" onClick={() => OpenAddEditCourse(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    {err ?
                        <Error text={err} />
                    :
                        <Fragment>
                            {!loading ?
                                <div className="row">                
                                    <div className="col-12 col-sm-4">
                                        <label className="input-label">Interní název kurzu </label>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => SetFormDataValue(e)} />
                                        </div>
                                    </div> 
                                    <div className="col-12 col-sm-4">
                                        
                                        <label className="input-label">Dotazník, který se spustí po nákupu</label>
                                        <div className="form-group">
                                            <select value={formData.questionnaireID} name="questionnaireID" className="form-control" onChange={(e) => SetFormDataValue(e)}>
                                                <option key={0} value={0}>--- vyberte dotazník ---</option>
                                                {allQuestionnaires.map((item) => {
                                                    return(
                                                        <option key={item.questionnaireID} value={item.questionnaireID}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> 
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <label className="input-label">Průvodce, který vede tento kurz</label>
                                        <div className="form-group">
                                            <select value={formData.guideID} name="guideID" className="form-control" onChange={(e) => SetFormDataValue(e)}>
                                                <option key={0} value={0}>--- vyberte průvodce ---</option>
                                                {allGuides.map((item) => {
                                                    return(
                                                        <option key={item.customerID} value={item.customerID}>{item.astralName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <label className="input-label">Typ hlavičky v emailu</label>
                                        <div className="form-group">
                                            <select value={formData.emailHeaderTypeID} name="emailHeaderTypeID" className="form-control" onChange={(e) => SetFormDataValue(e)}>
                                                <option key={1} value={1}>Se sidem</option>
                                                <option key={2} value={2}>Pouze logo</option>
                                            </select>
                                        </div>
                                    </div>
 
                                    <div className="col-12">
                                        <label className="input-label">Registrační email ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="regEmail"
                                                value={GetLangValue("regEmail")}
                                                OnEditorChange={(content) => SetFormDataLangValue(content,"regEmail")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label className="input-label">Email který příjde VS poté, co je přidán pod průvodce ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="emailForVs"
                                                value={GetLangValue("emailForVs")}
                                                OnEditorChange={(content) => SetFormDataLangValue(content,"emailForVs")}
                                            />
                                            
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label className="input-label">Email který chodí 5.den v týdnu zdarma ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="freeWeekEmail"
                                                value={GetLangValue("freeWeekEmail")}
                                                OnEditorChange={(content) => SetFormDataLangValue(content,"freeWeekEmail")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label className="input-label">Email při zrušení členství týdnu zdarma ({formData.selectedLang})</label>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="cancelFreeWeekEmail"
                                                value={GetLangValue("cancelFreeWeekEmail")}
                                                OnEditorChange={(content) => SetFormDataLangValue(content,"cancelFreeWeekEmail")}
                                            />
                                            
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label style={{margin:0}} className="input-label no-bottom-margin">Hláška po úspěšném nákupu ({formData.selectedLang})</label>
                                        <p style={{margin:0, fontSize:"12px"}}>Zobrazuje se po dokončení registrace a nebo po vyplnění dotazníku</p>
                                        <div className="form-group">
                                            <TinyMCEBasic 
                                                name="successMessage"
                                                value={GetLangValue("successMessage")}
                                                OnEditorChange={(content) => SetFormDataLangValue(content,"successMessage")}
                                            />
                                            
                                        </div>
                                    </div>

                                </div>

                            :
                                <Loading />  
                            }  
                        </Fragment>
                    }
                            
                </div>
                <div className="modal-footer"> 
                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => AddCourse()}>{(selectedCourseID && selectedCourseID != 0 ? "Upravit" : "Přidat")}</button>}
                    <button type="button" className="btn btn-danger" onClick={() => OpenAddEditCourse(false)}>Zavřít</button>
                </div>
            </div>
        </Modal>
    )            
    
    
}

export default withApollo(AddEditCourse);