import Modal from 'react-modal';
import { SERVER_URL,REVIEW_IMAGE_URL } from '../../../Config';

const AddReview = ({isOpen,Close,allActiveReviews,selectedReviews,SelectReview}) => {

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={isOpen}
            onRequestClose={() => Close()}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Přidat recenze</h4>
                    <button type="button" className="close" onClick={() => Close()}>
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    {allActiveReviews.map((item) => {

                        var check = false;
                        if(selectedReviews.indexOf(item.reviewID) != -1)
                            check = true;

                        return(
                            <div key={item.reviewID} className="review">
                                <div className="review-checkbox">
                                    <input type="checkbox" checked={check} value={item.reviewID} onChange = {() => SelectReview(item.reviewID)} />
                                </div>
                                {item.photo ?
                                    <div>
                                        <img src={SERVER_URL + "/"+ REVIEW_IMAGE_URL + "/mala_" + item.photo} />
                                    </div>
                                :null}
                                <div className="review-text-content">
                                    <div className="review-text" dangerouslySetInnerHTML={{__html:item.text}}></div>
                                    <div className="review-signature" dangerouslySetInnerHTML={{__html:item.signature}}></div>
                                </div>
                                
                            </div>
                        )
                    })}

                </div>
                <div className="modal-footer"> 
                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => Close()}>Přidat</button>
                    <button type="button" className="btn btn-danger" onClick={() => Close()}>Zavřít</button>
                </div>
            </div>
        </Modal>
    );

}

export default AddReview;