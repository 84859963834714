/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací faktur
 */
import {GET_INVOICES,EXPORT_INVOICE,EXPORTED_INVOICES_TO_ZIP,GET_INVOICE_CREDIT_NOTES,EXPORT_INVOICE_CREDIT_NOTES,EXPORTED_INVOICE_CREDIT_NOTES_TO_ZIP} from '../Queries/invoice.js';
import Notification from '../../../Library/notification';
import moment from 'moment';

class Invoice {

    constructor(parent){
        this.parent = parent;
    }

    setDateFrom(date){
        this.parent.setState({...this.parent.state,invoiceListVariables:{...this.parent.state.invoiceListVariables,dateFrom: date}});
    }
    setDateTo(date){
        this.parent.setState({...this.parent.state,invoiceListVariables:{...this.parent.state.invoiceListVariables,dateTo: date}});
    }

    setDomain(e){

        var value = e.target.value;
        var arr = [...this.parent.state.invoiceListVariables.selectedDomains];

        var index = -1;
        for(let i in arr){
            if(arr[i] == value){
                index = i;
            }
        }

        if(index != -1){
            arr = [...arr.slice(0,index),...arr.slice(index+1)];
        }else{
            arr.push(value);
        }

        this.parent.setState({invoiceListVariables:{...this.parent.state.invoiceListVariables, selectedDomains:arr}});
    }

    setService(e){

        var value = e.target.value;
        var arr = [...this.parent.state.invoiceListVariables.selectedServices];

        var index = -1;
        for(let i in arr){
            if(arr[i] == value){
                index = i;
            }
        }

        if(index != -1){
            arr = [...arr.slice(0,index),...arr.slice(index+1)];
        }else{
            arr.push(value);
        }

        this.parent.setState({invoiceListVariables:{...this.parent.state.invoiceListVariables, selectedServices:arr}});
    }

    addInvoiceCreditNote(addInvoiceCreditNote,selectedIinvoiceID,creditNoteDate,reason){

        if(creditNoteDate){
            if(reason != ""){

                addInvoiceCreditNote({
                    variables:{
                        invoiceID:selectedIinvoiceID,
                        creditNoteDate,
                        reason
                    }
                })
            
            }else{
                let notify = new Notification();
                notify.setNotification(null,'Není vyplněn důvod.',false,true,this.parent.props.client);
            }
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste datum.',false,true,this.parent.props.client);
        }

    }

    updateList(cache,response,selectedID,variables){

        variables = {
            limit:variables.limit,
            offset:variables.offset,
            lang:variables.lang,
            dateFrom: moment(variables.dateFrom).format("YYYY-MM-DD 00:00:00"),
            dateTo:moment(variables.dateTo).format("YYYY-MM-DD 23:59:59"),
            selectedDomains:variables.selectedDomains,
            selectedServices:variables.selectedServices
        }

        const { allFilteredInvoices,allDomains,allServices,totalInvoicesPrice,totalInvoices} = cache.readQuery({ query: GET_INVOICES,variables:variables });
        
        var arr = [...allFilteredInvoices];
        
        arr.forEach((item,index) => {
            
            if(item.invoiceID == selectedID){

                arr[index].invoiceCreditNote = {invoiceCreditNoteID:response.data.addInvoiceCreditNote,__typename:"InvoiceCreditNote"};

            }

        });

        cache.writeQuery({ 
            query:GET_INVOICES,
            variables:variables,
            data:{
                allFilteredInvoices: arr,
                allDomains,
                allServices,
                totalInvoicesPrice,
                totalInvoices
            } 
        });
        
    }

    updateCreditNoteListAfterDelete(cache,response,selectedID,variables){

        const { allFilteredInvoiceCreditNotes } = cache.readQuery({query: GET_INVOICE_CREDIT_NOTES,variables:variables});
        
        var arr = [...allFilteredInvoiceCreditNotes];
        
        arr.forEach((item,index) => {
            
            if(item.invoiceCreditNoteID == selectedID){

                arr[index].deleted = 1;

            }

        });
        cache.writeQuery({ 
            query:GET_INVOICE_CREDIT_NOTES,
            variables:variables,
            data:{
                allFilteredInvoiceCreditNotes: arr
            } 
        });
        
    }

    invoiceExport(){

        this.parent.setState({showExport:true},async () => {

            var invoiceListVariables = {
                limit:1000000,
                offset:0,
                lang:"cz",
                dateFrom: moment(this.parent.state.invoiceListVariables.dateFrom).format("YYYY-MM-DD 00:00:00"),
                dateTo:moment(this.parent.state.invoiceListVariables.dateTo).format("YYYY-MM-DD 23:59:59"),
                selectedDomains:this.parent.state.invoiceListVariables.selectedDomains,
                selectedServices:this.parent.state.invoiceListVariables.selectedServices,
                
            }

            var invoices = await this.parent.props.client.query({ 
                query: GET_INVOICES,
                errorPolicy:"all",
                variables:invoiceListVariables, 
                fetchPolicy: 'network-only'
            });


            var invoiceCount = invoices.data.allFilteredInvoices.length;
            var completedCount = 0;

            this.parent.setState({invoiceCount,completedCount},() => {

                var arrCount = [];
                var li = 0;
                var loopInterval = setInterval(async () => {

                    this.parent.props.client.mutate({ 
                        mutation: EXPORT_INVOICE,
                        errorPolicy:"all",
                        variables:{
                            invoiceID: invoices.data.allFilteredInvoices[li].invoiceID,
                            domainID:  invoices.data.allFilteredInvoices[li].domainID
                        },
                    }).then((invoiceNumber) => {

                        arrCount.push(invoiceNumber.data.exportInvoice);
                          
                    });

                    if(li == invoiceCount - 1)clearInterval(loopInterval);

                    li++;

                },200);
                
                var inerval = setInterval(()=> {

                    this.parent.setState({completedCount:arrCount.length},() => {
                        if(this.parent.state.completedCount == invoiceCount){
                            
                            clearInterval(inerval);

                            this.parent.props.client.mutate({ 
                                mutation: EXPORTED_INVOICES_TO_ZIP,
                                errorPolicy:"all",
                                variables:{
                                    invoices:arrCount  
                                },
                            }).then((zipName) => {
       
                                setTimeout(() => {
                                    this.parent.setState({loadingExport:false,zipName:zipName.data.exportedInvoicesToZip});
                                },1000);
                                  
                            });

                               
                        }
                    });

                },500);


            });


        });

    }

    async invoiceExportToCSV(){

        

        var invoiceListVariables = {
            limit:1000000,
            offset:0,
            lang:"cz",
            dateFrom: moment(this.parent.state.invoiceListVariables.dateFrom).format("YYYY-MM-DD 00:00:00"),
            dateTo:moment(this.parent.state.invoiceListVariables.dateTo).format("YYYY-MM-DD 23:59:59"),
            selectedDomains:this.parent.state.invoiceListVariables.selectedDomains,
            selectedServices:this.parent.state.invoiceListVariables.selectedServices,
            
        }

        var invoices = await this.parent.props.client.query({ 
            query: GET_INVOICES,
            errorPolicy:"all",
            variables:invoiceListVariables, 
            fetchPolicy: 'network-only'
        });


        var invoiceCount = invoices.data.allFilteredInvoices;
        

        var data = [
            ['Číslo faktury', 'Jméno', 'Příjmení', 'Email', 'Tel', 'Položka faktury', 'Celková částka', 'Datum vložení','Datum splatnosti']
        ];

        for(let val of invoices.data.allFilteredInvoices){

            data.push([
                val.invoiceNumber,
                val.name,
                val.surname,
                val.email,
                val.tel,
                val.items[0].text,
                val.totalPrice,
                val.dateAddStr,
                val.dueDateStr
            ]);

        }
        

        var BOM = "\ufeff";
        var csvContent = '';
        data.forEach(function(infoArray, index) {
            var dataString = infoArray.join(';');
            csvContent += index < data.length ? dataString + '\n' : dataString;
        });

        csvContent = BOM + csvContent;

        var download = function(content, fileName, mimeType) {
            
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';
        
            if (navigator.msSaveBlob) { // IE10

                navigator.msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);

            } else if (URL && 'download' in a) { 

                a.href = URL.createObjectURL(new Blob([content], {type: mimeType }));

                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            } else {

                window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        }
        
        download(csvContent, 'faktury.csv', 'text/csv;encoding:utf-8');

    }

    invoiceCreditNoteExport(){

        this.parent.setState({showExport:true},async () => {

            var invoiceListVariables = {
                limit:1000000,
                offset:0,
                lang:"cz",
                hasDeleted:false,
                dateFrom: this.parent.state.invoiceListVariables.dateFrom,
                dateTo: this.parent.state.invoiceListVariables.dateTo,
                selectedDomains:this.parent.state.invoiceListVariables.selectedDomains,
                selectedServices:this.parent.state.invoiceListVariables.selectedServices,
                
            }

            var invoices = await this.parent.props.client.query({ 
                query: GET_INVOICE_CREDIT_NOTES,
                errorPolicy:"all",
                variables:invoiceListVariables, 
                fetchPolicy: 'network-only'
            });


            var invoiceCount = invoices.data.allFilteredInvoiceCreditNotes.length;
            var completedCount = 0;

            this.parent.setState({invoiceCount,completedCount},() => {

                var arrCount = [];
                var li = 0;
                var loopInterval = setInterval(async () => {

                    this.parent.props.client.mutate({ 
                        mutation: EXPORT_INVOICE_CREDIT_NOTES,
                        errorPolicy:"all",
                        variables:{
                            invoiceCreditNoteID: invoices.data.allFilteredInvoiceCreditNotes[li].invoiceCreditNoteID,
                            domainID:  invoices.data.allFilteredInvoiceCreditNotes[li].invoice.domainID
                        },
                    }).then((invoiceNumber) => {

                        arrCount.push(invoiceNumber.data.exportInvoiceCreditNote);
                          
                    })

                    if(li == invoiceCount - 1)clearInterval(loopInterval);

                    li++;

                },200);

                var inerval = setInterval(()=> {

                    this.parent.setState({completedCount:arrCount.length},() => {
                        if(this.parent.state.completedCount == invoiceCount){
                            
                            clearInterval(inerval);

                            this.parent.props.client.mutate({ 
                                mutation: EXPORTED_INVOICE_CREDIT_NOTES_TO_ZIP,
                                errorPolicy:"all",
                                variables:{
                                    invoiceCreditNotes:arrCount  
                                },
                            }).then((zipName) => {
        
                                setTimeout(() => {
                                    this.parent.setState({loadingExport:false,zipName:zipName.data.exportedInvoiceCreditNotesToZip});
                                },1000);
                                  
                            });

                               
                        }
                    });

                },500);


            });


        });

    }

    async invoiceCreditNoteExportToCSV(){

        var invoiceListVariables = {
            limit:1000000,
            offset:0,
            lang:"cz",
            dateFrom: this.parent.state.invoiceListVariables.dateFrom,
            dateTo: this.parent.state.invoiceListVariables.dateTo,
            selectedDomains:this.parent.state.invoiceListVariables.selectedDomains,
            selectedServices:this.parent.state.invoiceListVariables.selectedServices,
            
        }

        var invoices = await this.parent.props.client.query({ 
            query: GET_INVOICE_CREDIT_NOTES,
            errorPolicy:"all",
            variables:invoiceListVariables, 
            fetchPolicy: 'network-only'
        });
       

        var data = [
            ['Číslo dobropisu', 'Číslo faktury', 'Datum dobropisu', 'Jméno', 'Příjmení', 'Email', 'Tel', 'Celková částka']
        ];

        for(let val of invoices.data.allFilteredInvoiceCreditNotes){

            data.push([
                val.creditNoteNumber,
                val.invoice.invoiceNumber,
                moment(val.creditNoteDate).format("D.M.YYYY"),
                val.invoice.name,
                val.invoice.surname,
                val.invoice.email,
                val.invoice.tel,
                (-1)*val.invoice.totalPrice
            ]);

        }
        
        var BOM = "\ufeff";
        var csvContent = '';
        data.forEach(function(infoArray, index) {
            var dataString = infoArray.join(';');
            csvContent += index < data.length ? dataString + '\n' : dataString;
        });

        csvContent = BOM + csvContent;

        var download = function(content, fileName, mimeType) {
            
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';
        
            if (navigator.msSaveBlob) { // IE10

                navigator.msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);

            } else if (URL && 'download' in a) { 

                a.href = URL.createObjectURL(new Blob([content], {type: mimeType }));

                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            } else {

                window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        }
        
        download(csvContent, 'dobropisy.csv', 'text/csv;encoding:utf-8');

    }

}

export default Invoice;