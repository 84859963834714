import gql from 'graphql-tag';

export const ADD_EDIT_NOTIFICATION = gql`
    mutation AddEditNotification(
        $notificationID:ID,
        $sendEmailAboutReadToGuideID: ID, 
        $isPopUp:Int,
        $selectedCustomers:[ID]
        $langs:[NotificationLangsInput]
    ){
        AddEditNotification(
            notificationID:$notificationID,
            sendEmailAboutReadToGuideID: $sendEmailAboutReadToGuideID, 
            isPopUp:$isPopUp,
            selectedCustomers:$selectedCustomers,
            langs:$langs
        ){
            notificationID
            title
            dateAdd
            selectedCustomers
        }
    }
`;

export const GET_NOTIFICATIONS = gql`
    query AllNotifications($lang:String){
        AllNotifications(lang:$lang){
            notificationID
            dateAdd
            title
        }
    }
`;

export const GET_NOTIFICATION = gql`
    query NotificationWithLangs($notificationID: ID){
        NotificationWithLangs(notificationID:$notificationID){
            notificationID
            sendEmailAboutReadToGuideID
            isPopUp
            langs{
                lang
                title
                text
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allGuides{
            customerID
            astralName
        }
    }
`;
export const DELETE_NOTIFICATIONS = gql`
    mutation DeleteNotifications($notificationIDs:ID) {
        DeleteNotifications(notificationIDs:$notificationIDs)
    }
`;