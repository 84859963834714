/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_EVS_MONTH_DATA} from '../Queries/eliteVS.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import EliteVSLib from '../Library/eliteVSMonthData';
import moment from 'moment';
import EliteVSMonthDataImages from './EliteVSMonthDataImages';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic.js';


const INITIAL_STATE = {
    err:"",
    selectedTab:1,
    selectedEvsMonthDataID:0,
    formData:{
        evsMonthDataID:0,
        month:moment().format("M"),
        year: moment().format("YYYY"),
        numberOfWinners:0,
        langs:[],
        selectedLang:'cz'
    },
    allLanguageMutations:[]
}

var intYear = parseInt(INITIAL_STATE.formData.year);
var years = [];
var numOfWiners = [];
for(let i = 2022; i <= intYear; i++){
    years.push(i);
}
for(let i = 0; i <= 60; i++){
    numOfWiners.push(i);
}

class AddEditEliteVSMonthData extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.eliteVSLib = new EliteVSLib(this); 
    }

    componentDidMount(){
        this.eliteVSLib.getData();
    }    

    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab 
        }); 
    }

    render(){

        var {err,selectedTab,selectedEvsMonthDataID} = this.state;
        var {listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_EVS_MONTH_DATA}
                errorPolicy = "all"

                onCompleted = {(data) => {

                    if(data.addEditEvsMonthData.error){

                        let notify = new Notification();
                        notify.setNotification(null,data.addEditEvsMonthData.error,false,true,this.props.client);

                    }else{

                        this.props.refetch();

                        let notify = new Notification();

                        if(selectedEvsMonthDataID && selectedEvsMonthDataID != 0) {
                            notify.setNotification(null,'Úspěšně uloženo',true,false,this.props.client);
                            this.props.openCloseModal(false);
                        }else{
                            notify.setNotification(null,'Úspěšně přidáno',true,false,this.props.client);
                            this.setState({selectedEvsMonthDataID:data.addEditEvsMonthData.evsMonthDataID,formData:{...this.state.formData, evsMonthDataID:data.addEditEvsMonthData.evsMonthDataID}});
                    
                        }

                    }
                }}
                
            >
            {
                (addEditEvsMonthData,{loading, error}) => {

                    const {selectedLang,year,month,numberOfWinners} = this.state.formData;
                    const {allLanguageMutations} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedEvsMonthDataID && selectedEvsMonthDataID != 0 ? "Úprava" : "Přidání")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.eliteVSLib.formLangObjectHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.languageID}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <div>
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={(selectedTab == 2 ? "nav-link active" : (selectedEvsMonthDataID ? 'nav-link' : 'nav-link disabled'))} href="#main" onClick={(e) => this.showTab(e,2)}>Obrázky</a>
                                                </li>
                                            </ul>

                                            {selectedTab == 1 ? 
                                                <Fragment>
                                                    {!loading ?
                                                    <div className="row">                
                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">Měsíc </label>
                                                            <div className="form-group">
                                                                <select value={month} name="month" className="form-control" onChange={(e) => this.eliteVSLib.formHandle(e)}>
                                                                    <option value={1}>Leden</option>
                                                                    <option value={2}>Únor</option>
                                                                    <option value={3}>Březen</option>
                                                                    <option value={4}>Duben</option>
                                                                    <option value={5}>Květen</option>
                                                                    <option value={6}>Červen</option>
                                                                    <option value={7}>Červenec</option>
                                                                    <option value={8}>Srpen</option>
                                                                    <option value={9}>Září</option>
                                                                    <option value={10}>Říjen</option>
                                                                    <option value={11}>Listopad</option>
                                                                    <option value={12}>Prosinec</option>
                                                                </select>
                                                                
                                                            </div>
                                                        </div>  
                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">Rok </label>
                                                            <div className="input-group">

                                                                <select value={year} name="year" className="form-control" onChange={(e) => this.eliteVSLib.formHandle(e)}>
                                                                    {years.map((item,index) => (
                                                                        <option key={index} value={item}>{item}</option>
                                                                    ))}
                                                                </select>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">Počet výherců </label>
                                                            <div className="input-group">

                                                                <select value={numberOfWinners} name="numberOfWinners" className="form-control" onChange={(e) => this.eliteVSLib.formHandle(e)}>
                                                                    {numOfWiners.map((item,index) => (
                                                                        <option key={index} value={item}>{item == 0 ? "6%" : item}</option>
                                                                    ))}
                                                                </select>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="input-label">Text nad fotkama ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="emailText"
                                                                    value={this.eliteVSLib.getLangValue("text")}
                                                                    OnEditorChange={(content) => this.eliteVSLib.formLangHandleEditor(content,"text")}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :

                                                    <Loading />  

                                                    }  
                                                </Fragment>
                                            :null}

                                            {selectedTab == 2 ? 
                                                <EliteVSMonthDataImages selectedEvsMonthDataID={selectedEvsMonthDataID} />
                                            :null}
                                        </div>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.eliteVSLib.addEditEvsMonthData(addEditEvsMonthData)}>{(selectedEvsMonthDataID && selectedEvsMonthDataID != 0 ? "Upravit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditEliteVSMonthData);