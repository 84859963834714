/*
    Přidání kategorie
*/
import React,{Fragment,useEffect, useMemo } from 'react';
import {useMutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_QUESTIONNAIRE,GET_QUESTIONNAIRES} from '../Queries/questionnaire';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import UseAddQuestionnaire from '../Library/useAddQuestionnaire';
import remove from '../../../Public/Images/remove.svg';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';

const AddEditQuestionnaire = ({listVariables,selectedQuestionnaireID,OpenAddEditQuestionnaire,client}) => {

    const [AddEditQuestionnaire,{loading:addLoading, error:addError}] = useMutation(ADD_EDIT_QUESTIONNAIRE,{

        update: async (cache, response) => {
            
            const { getAllQuestionnaires } = cache.readQuery({ query: GET_QUESTIONNAIRES,variables:listVariables });
            if(!(selectedQuestionnaireID && selectedQuestionnaireID != 0))
            {
                cache.writeQuery({ 
                    query:GET_QUESTIONNAIRES,
                    variables:listVariables,
                    data:{
                        getAllQuestionnaires: [...getAllQuestionnaires,response.data.addEditQuestionnaire]
                    } 
                });

            }else{

                var arr = [...getAllQuestionnaires];
                arr.forEach((item,index) => {
                    
                    if(item.questionnaireID == response.data.addEditQuestionnaire.questionnaireID)
                    {
                        arr[index].questionnaireID = response.data.addEditQuestionnaire.questionnaireID;
                        arr[index].name            = response.data.addEditQuestionnaire.name;
                    }
                });
                cache.writeQuery({ 
                    query:GET_QUESTIONNAIRES,
                    variables:listVariables,
                    data:{
                        getAllQuestionnaires: arr
                    } 
                });
            }

            let notify = new Notification();

            if(selectedQuestionnaireID && selectedQuestionnaireID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
            else notify.setNotification(cache,'Úspěšně přidáno',true,false);

        },
        onCompleted: () => {
            OpenAddEditQuestionnaire(false);
        }
    });

    const {
        allLanguageMutations,
        formData,
        allQuestionTypes,
        loading,
        error,
        GetData,
        SetFormDataValue,
        SetFormDataLangValue,
        GetLangValue,
        GetStepLangValue,
        AddQuestionnaire,
        AddQuestionnaireStep,
        AddQuestionnaireStepItem,
        SetStepLangValue,
        SetStepItemLangValue,
        SetStepQuestionType,
        RemoveStep,
        RemoveStepItem,
        SortSteps,
        SortStepItems
    } = UseAddQuestionnaire(AddEditQuestionnaire,selectedQuestionnaireID,client);

    
    useEffect(() => {
        GetData();
    },[])

    var err = "";

    if(addError || error){
        const helper = new Helper(); 
        err = helper.getApolloErrorText(addError || error);
    }
  
    return(

        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => OpenAddEditQuestionnaire(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(selectedQuestionnaireID && selectedQuestionnaireID != 0 ? "Úprava kurzu" : "Přidání kurzu")}</h4>
                    <div className="ml-auto">
                        <select value={formData.selectedLang} className="form-control" name="selectedLang" onChange={(e) => SetFormDataValue(e)}>
                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                <option key={index} value={item.suffix}> {item.suffix} </option>
                            ))}
                        </select>
                    </div>
                    <button type="button" className="close ml-3" onClick={() => OpenAddEditQuestionnaire(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    {err ?
                        <Error text={err} />
                    :
                        <Fragment>
                            {!loading ?
                                <>
                                    <div className="row">  

                                        <div className="col-12 col-sm-4">
                                            <label className="input-label">Interní název kurzu </label>
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => SetFormDataValue(e)} />
                                            </div>
                                        </div> 
                                        <div className="col-12 ">
                                            <label className="input-label no-margin">Uvítací zpráva ({formData.selectedLang})</label>
                                            <p className="no-margin" style={{fontSize:"12px"}}>Zpráva, která se ukáže před tím, než se začne vyplňovat dotazník</p>
                                            <div className="form-group">
                                                <TinyMCEBasic 
                                                    name="welcomeMessage"
                                                    value={GetLangValue("welcomeMessage")}
                                                    OnEditorChange={(content) => SetFormDataLangValue(content,"welcomeMessage")}
                                                />
                                                
                                            </div>
                                        </div> 
                                        
                                    </div>

                                    <div className="form-group">
                                        
                                        <div className="data-list">
                                        
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>Otázka</div>
                                                    <div className="static text-right">Typ otázky</div>
                                                </div>
                                            </div>
                                            <div className="questionaire-step">

                                                <DragDropContext onDragEnd={(result) => SortSteps(result)}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {formData.questionnaireSteps.map((item,index) => {

                                                                    if(item.deleted) return "";
                                                                    
                                                                    return(

                                                                        <Draggable key={index} draggableId={index+1} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div  
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    
                                                                                >
                                                                                    <div className="data-list-item first">
                                                                                        <div className="data-list-item-content">
                                                                                            <img {...provided.dragHandleProps} src={move} className="remove-icon mr-2" />
                                                                                            <input placeholder="Zde napište otázku" className="form-control" value={GetStepLangValue(item.langs,"question")} type="text" name="question" onChange={(e) => SetStepLangValue(e,index)} />
                                                                                            <select value={item.questionTypeID} style={{width:"150px"}} className="form-control ml-2" onChange={(e) => SetStepQuestionType(index,e.target.value)}>
                                                                                                {allQuestionTypes.map((item,index) => (
                                                                                                    <option key={item.questionTypeID} value={item.questionTypeID}>{item.type}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                            <img className="remove-icon ml-2" onClick={() => RemoveStep(index)} src={remove} />
                                                                                        </div>
                                                                                        {item.questionTypeID != 1 ?
                                                                                            <div className="items">

                                                                                                <DragDropContext onDragEnd={(result) => SortStepItems(index,result)}>
                                                                                                    <Droppable droppableId="droppable2">
                                                                                                        {(provided, snapshot) => (
                                                                                                            <div
                                                                                                                {...provided.droppableProps}
                                                                                                                ref={provided.innerRef}
                                                                                                            >
                                                                                                                {item.items.map((it,ind) => {

                                                                                                                    if(it.deleted) return"";
                                                                                                                    return(
                                                                                                                        <Draggable key={ind} draggableId={ind+1} index={ind}>
                                                                                                                        {(provided, snapshot) => (
                                                                                                                            <div  
                                                                                                                                ref={provided.innerRef}
                                                                                                                                {...provided.draggableProps}
                                                                                                                                
                                                                                                                            >
                                                                                                                                <div className="data-list-item">
                                                                                                                                    <div className="data-list-item-content"> 
                                                                                                                                        <img {...provided.dragHandleProps} src={move} className="remove-icon mr-2" />
                                                                                                                                        <input placeholder="Zde napište odpověď" className="form-control" value={GetStepLangValue(it.langs,"text")} type="text" name="text" onChange={(e) => SetStepItemLangValue(e,index,ind)} />
                                                                                                                                        <img className="remove-icon ml-2" onClick={() => RemoveStepItem(index,ind)} src={remove} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            )}
                                                                                                                        </Draggable>
                                                                                                                    )
                                                                                                                })}
                                                                                                                {provided.placeholder}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Droppable>
                                                                                                </DragDropContext>
                                                                                                <button onClick={() => AddQuestionnaireStepItem(index)} className={"btn btn-primary mt-2 d-block"}>Přidat odpověď</button>
                                                                                            </div>
                                                                                        :null}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <button onClick={() => AddQuestionnaireStep()} className="btn btn-primary">Přidat otázku</button>

                                </>

                            :
                                <Loading />  
                            }  
                        </Fragment>
                    }
                            
                </div>
                <div className="modal-footer"> 
                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => AddQuestionnaire()}>{(selectedQuestionnaireID && selectedQuestionnaireID != 0 ? "Upravit" : "Přidat")}</button>}
                    <button type="button" className="btn btn-danger" onClick={() => OpenAddEditQuestionnaire(false)}>Zavřít</button>
                </div>
            </div>
        </Modal>
    )            
    
    
}

export default withApollo(AddEditQuestionnaire);